import { useState, useEffect, useRef } from "react";
import { useRouter } from "next/router";
export function useScrollDirection() {
  var threshold = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  var router = useRouter();
  var lastScrollY = useRef(0);
  var _useState = useState(),
    direction = _useState[0],
    setDirection = _useState[1];
  useEffect(function () {
    lastScrollY.current = window.pageYOffset;
    var ticking = false;
    var updateScrollDir = function updateScrollDir() {
      var scrollY = window.pageYOffset;
      if (Math.abs(scrollY - lastScrollY.current) < threshold) {
        ticking = false;
        return;
      }
      setDirection(scrollY > lastScrollY.current ? "down" : "up");
      lastScrollY.current = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };
    var onScroll = function onScroll() {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };
    window.addEventListener("scroll", onScroll);
    return function () {
      window.removeEventListener("scroll", onScroll);
    };
  }, [direction]);
  useEffect(function () {
    var resetLastScrollY = function resetLastScrollY() {
      lastScrollY.current = window.pageYOffset;
      setDirection(null);
    };
    router.events.on("routeChangeComplete", resetLastScrollY);
    return function () {
      router.events.off("routeChangeComplete", resetLastScrollY);
    };
  }, []);
  return direction;
}