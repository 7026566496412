import _taggedTemplateLiteral from "/build/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13, _templateObject14, _templateObject15;
import { gql } from "@apollo/client";
export var pageInfoFields = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  fragment pageInfoFields on PageInfo {\n    offset\n    limit\n    totalCount\n    hasNextPage\n  }\n"])));
export var advertisingFields = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  fragment advertisingFields on Advert {\n    id\n    name\n    description\n    url\n    targetBlank\n    sort\n    thumbnailUrl\n    copyright\n  }\n"])));
export var rightFields = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  fragment rightFields on Right {\n    id\n    name\n    exercise_term\n    exercise_term_unit\n    view_term\n    view_term_unit\n    view_limit_date\n    validity_start_at\n    validity_end_at\n    price\n    discount_price\n    isSvod\n    package_type\n    discount_description\n    contractAllowed\n  }\n"])));
export var channelMetaFields = gql(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  fragment channelMetaFields on ChannelMeta {\n    id\n    schema_key\n    name\n    header\n    description\n    thumbnailUrl\n    copyright\n    delivery_start_at\n    delivery_end_at\n    rights_control_type\n    rating\n    isAllowedRating\n    rights {\n      ... on Right {\n        id\n        name\n        price\n        contractAllowed\n      }\n    }\n    genres {\n      id\n      name\n    }\n    adultFlag\n    meta_keywords\n    meta_description\n    live_flag\n    video_flag\n    heroImage {\n      pc\n      mb\n    }\n    distributor\n  }\n"])));
export var episodeMetaFields = gql(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  fragment episodeMetaFields on EpisodeMeta {\n    id\n    schema_key\n    name\n    header\n    description\n    thumbnailUrl\n    copyright\n    delivery_start_at\n    delivery_end_at\n    rights_control_type\n    rating\n    isAllowedRating\n    rights {\n      ... on Right {\n        id\n        name\n        price\n      }\n    }\n    genres {\n      id\n      name\n    }\n    adultFlag\n    production_country\n    production_year\n    distributor\n    cast\n    staff\n    duration\n    meta_keywords\n    meta_description\n    channel {\n      ... on ChannelMeta {\n        id\n        name\n      }\n    }\n    tvShow {\n      ... on TVShowMeta {\n        id\n        name\n        episode_num\n      }\n    }\n    area_control_flag\n    is_live\n    recommend\n    start_over_flag\n    catch_up_flag\n    encode_start_date\n    encode_end_date\n    source_epg_id\n    plalaFlag\n  }\n"])));
export var liveMetaFields = gql(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  fragment liveMetaFields on LiveMeta {\n    id\n    schema_key\n    name\n    header\n    description\n    thumbnailUrl\n    copyright\n    delivery_start_at\n    delivery_end_at\n    rights_control_type\n    parents {\n      ... on TVShowMeta {\n        id\n        name\n        episode_num\n      }\n    }\n    rating\n    isAllowedRating\n    rights {\n      ... on Right {\n        id\n        name\n        price\n      }\n    }\n    genres {\n      id\n      name\n    }\n    adultFlag\n    production_country\n    production_year\n    distributor\n    cast\n    staff\n    duration\n    meta_keywords\n    meta_description\n    channel {\n      ... on ChannelMeta {\n        id\n        name\n      }\n    }\n    tvShow {\n      ... on TVShowMeta {\n        id\n        name\n        episode_num\n      }\n    }\n    area_control_flag\n    is_live\n    recommend\n    start_over_flag\n    catch_up_flag\n    encode_start_date\n    encode_end_date\n    source_epg_id\n    plalaFlag\n  }\n"])));
export var tvShowMetaFields = gql(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  fragment tvShowMetaFields on TVShowMeta {\n    id\n    schema_key\n    name\n    header\n    description\n    thumbnailUrl\n    copyright\n    delivery_start_at\n    delivery_end_at\n    rights_control_type\n    rating\n    isAllowedRating\n    rights {\n      ... on Right {\n        id\n        name\n        price\n        rights_control_type\n      }\n    }\n    genres {\n      id\n      name\n    }\n    adultFlag\n    production_country\n    production_year\n    cast\n    staff\n    episode_num\n    meta_keywords\n    meta_description\n    channel {\n      ... on ChannelMeta {\n        id\n        name\n      }\n    }\n    distributor\n  }\n"])));
export var productFields = gql(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  fragment productFields on Product {\n    id\n    name\n    thumbnailUrl\n    description\n    product_type\n    sales_start_at\n    sales_end_at\n    price\n    priceWithoutTax\n    rightIds\n    purchaseAllowed\n  }\n"])));
export var entitlementFields = gql(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  fragment entitlementFields on Entitlement {\n    id\n    user_id\n    right_id\n    start_at\n    end_at\n    exercised_at\n    exercise_duration\n    exercise_duration_unit\n    exercisable_start_at\n    exercisable_end_at\n    suspend_flag\n  }\n"])));
export var voucherFilds = gql(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  fragment voucherFilds on Voucher {\n    id\n    name\n    description\n    discountPrice\n    limitPrice\n    trialExtendTerm\n    trialExtendTermUnit\n    trialExtendEndOf\n    validityStartAt\n    validityEndAt\n  }\n"])));
export var voucherCodeFields = gql(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n  ", "\n  fragment voucherCodeFields on VoucherCode {\n    id\n    code\n    voucherId\n    validityStartAt\n    validityEndAt\n    expire_at\n    isAvailable\n    voucher {\n      ...voucherFilds\n    }\n  }\n"])), voucherFilds);
export var campaignFields = gql(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n  fragment campaignFields on Campaign {\n    id\n    name\n    header\n    description\n    validityStartAt\n    validityEndAt\n    broadcastDiscount\n    discountApplyCount\n  }\n"])));
export var coursePriceFields = gql(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["\n  ", "\n  fragment coursePriceFields on CoursePrice {\n    id\n    price\n    payment_amount\n    validity_start_at\n    validity_end_at\n    campaignId\n    campaign {\n      ...campaignFields\n    }\n  }\n"])), campaignFields);
export var courseFields = gql(_templateObject14 || (_templateObject14 = _taggedTemplateLiteral(["\n  ", "\n  fragment courseFields on Course {\n    id\n    name\n    thumbnailUrl\n    description\n    validity_start_at\n    validity_end_at\n    price\n    priceWithoutTax\n    defaultPrice\n    defaultPriceWithoutTax\n    discount_price\n    active_pricing {\n      ... on CoursePrice {\n        ...coursePriceFields\n      }\n    }\n    rightIds\n    trial_flag\n    trial_date\n    trialTerm\n    trial_term_unit\n    trialTermText\n    trialText\n    trialDate\n    ref_id\n    valuePlan\n    questionId\n    trialCampaignFlag\n    contractAllowed\n  }\n"])), coursePriceFields);
export var subscriptionFields = gql(_templateObject15 || (_templateObject15 = _taggedTemplateLiteral(["\n  ", "\n  fragment subscriptionFields on Subscription {\n    subscription_id\n    course_id\n    payment_method_id\n    payment_instrument_id\n    initialTransactionAt\n    nextTransactionAt\n    status\n    course {\n      ... on Course {\n        ...courseFields\n      }\n    }\n    payment_instrument {\n      values {\n        card_number\n        card_type\n      }\n    }\n    withdraw_at\n    trialStartAt\n    trialEndAt\n    entitlements {\n      right_id\n    }\n    suspend_flag\n  }\n"])), courseFields);