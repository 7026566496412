import _taggedTemplateLiteral from "/build/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13, _templateObject14, _templateObject15, _templateObject16, _templateObject17, _templateObject18, _templateObject19, _templateObject20, _templateObject21, _templateObject22, _templateObject23, _templateObject24, _templateObject25, _templateObject26, _templateObject27, _templateObject28, _templateObject29, _templateObject30, _templateObject31, _templateObject32, _templateObject33, _templateObject34, _templateObject35, _templateObject36;
import { gql } from "@apollo/client";
import { channelMetaFields, episodeMetaFields, liveMetaFields, pageInfoFields, tvShowMetaFields, entitlementFields } from "./fragments";
export var userFavoritesFields = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  fragment userFavoritesFields on UserFavorites {\n    id\n    metas {\n      ... on EpisodeMeta {\n        id\n      }\n      ... on LiveMeta {\n        id\n      }\n      ... on TVShowMeta {\n        id\n      }\n      ... on ChannelMeta {\n        id\n      }\n    }\n  }\n"])), episodeMetaFields, liveMetaFields, tvShowMetaFields, channelMetaFields, pageInfoFields);
export var profileFields = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  fragment profileFields on ProfileUser {\n    uuid\n    id\n    displayName: display_name\n    birthday\n    age\n    sex\n    ownerProfile\n    needPasscode\n    icon\n    rating\n  }\n"])));
export var accountFields = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  ", "\n  fragment accountFields on AccountUser {\n    id\n    uuid\n    displayName: display_name\n    birthday\n    age\n    sex\n    mailAddress: mail_address\n    firstName: first_name\n    lastName: last_name\n    fullName\n    rightsFreeFlag: rights_free_flag\n    currentProfile {\n      ...profileFields\n    }\n    profiles {\n      ...profileFields\n    }\n    test_flg\n    mySkpRelation\n    mySkpEmail\n    hashedUserId\n    receiveAdvertisingMailType\n    receiveAdvertisingMailAdultType\n  }\n"])), profileFields);
export var AUTH_LOGIN_MUTATION = gql(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  ", "\n  mutation AuthLogin(\n    $loginid: String!\n    $password: String!\n    $rememberme: Boolean\n    $signature: String!\n  ) {\n    login(\n      loginid: $loginid\n      password: $password\n      rememberme: $rememberme\n      signature: $signature\n    ) {\n      token\n      user {\n        ...accountFields\n      }\n    }\n  }\n"])), accountFields);
export var AUTH_USER = gql(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  ", "\n  query AuthUser {\n    user {\n      ...accountFields\n    }\n  }\n"])), accountFields);
export var AUTH_USER_WITH_FAVORITES = gql(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  query AuthUserWithFavorites {\n    user {\n      ...accountFields\n    }\n    favorites: userFavorites {\n      ...userFavoritesFields\n    }\n  }\n"])));
export var AUTH_LOGOUT_MUTATION = gql(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  mutation AuthLogout($force: Boolean = false) {\n    logout(force: $force) {\n      result\n    }\n  }\n"])));
export var AUTH_SELECT_PROFILE_MUTATION = gql(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  ", "\n  mutation AuthSelectProfile(\n    $profileId: Int!\n    $passcode: String\n    $type: String\n  ) {\n    selectProfile(profileId: $profileId, passcode: $passcode, type: $type) {\n      ...accountFields\n    }\n  }\n"])), accountFields);
export var AUTH_PROFILE_MUTATION = gql(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  mutation AuthProfile($profileId: Int, $passcode: String) {\n    authProfile(profileId: $profileId, passcode: $passcode) {\n      result\n    }\n  }\n"])));
export var ACCOUNT_SIGNUP_PINCODE_MUTATION = gql(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  mutation AccountSignupPinCode($mailAddress: String!) {\n    signupPincode(mailAddress: $mailAddress) {\n      result\n    }\n  }\n"])));
export var ACCOUNT_SEND_PINCODE_MUTATION = gql(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n  mutation AccountSendPinCode($mailAddress: String!) {\n    sendPincode(mailAddress: $mailAddress) {\n      result\n    }\n  }\n"])));
export var ACCOUNT_SEND_PROFILE_RESET_TOKEN = gql(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n  mutation AccountSendProfilePinResetToken {\n    sendProfilePinResetToken {\n      result\n    }\n  }\n"])));
export var ACCOUNT_SEND_PASSWORD_RESET_TOKEN = gql(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["\n  mutation AccountSendPasswordResetToken($mailAddress: String!) {\n    sendPasswordResetToken(mailAddress: $mailAddress) {\n      result\n    }\n  }\n"])));
export var ACCOUNT_PASSWORD_RESET = gql(_templateObject14 || (_templateObject14 = _taggedTemplateLiteral(["\n  mutation AccountPasswordReset($password: String!, $token: String!) {\n    passwordReset(password: $password, token: $token) {\n      result\n    }\n  }\n"])));
export var ACCOUNT_PASSCODE_RESET = gql(_templateObject15 || (_templateObject15 = _taggedTemplateLiteral(["\n  mutation AccountPasscodeReset($passcode: String!, $token: String!) {\n    passcodeReset(passcode: $passcode, token: $token) {\n      result\n    }\n  }\n"])));
export var ACCOUNT_VERIFY_PINCODE_MUTATION = gql(_templateObject16 || (_templateObject16 = _taggedTemplateLiteral(["\n  mutation AccountVerifyPinCode($code: String!, $mailAddress: String!) {\n    verifyPinCode(code: $code, mailAddress: $mailAddress) {\n      verified\n      error\n    }\n  }\n"])));
export var ACCOUNT_VERIFY_PASSWORD_TOKEN_MUTATION = gql(_templateObject17 || (_templateObject17 = _taggedTemplateLiteral(["\n  mutation AccountVerifyPasswordToken($token: String!) {\n    verifyPasswordToken(token: $token) {\n      verified\n      error\n    }\n  }\n"])));
export var ACCOUNT_SIGNUP_MUTATION = gql(_templateObject18 || (_templateObject18 = _taggedTemplateLiteral(["\n  ", "\n  mutation AccountSignup(\n    $code: String!\n    $mailAddress: String!\n    $password: String!\n    $firstName: String!\n    $lastName: String!\n    $birthday: String!\n    $sex: String!\n    $terms: String!\n    $signature: String!\n  ) {\n    signup(\n      code: $code\n      mailAddress: $mailAddress\n      password: $password\n      firstName: $firstName\n      lastName: $lastName\n      birthday: $birthday\n      sex: $sex\n      terms: $terms\n      signature: $signature\n    ) {\n      token\n      user {\n        ...accountFields\n      }\n    }\n  }\n"])), accountFields);
export var ADD_FAVORITE_MUTATION = gql(_templateObject19 || (_templateObject19 = _taggedTemplateLiteral(["\n  ", "\n  mutation AddFavorite($id: Int!) {\n    addFavorite(id: $id) {\n      result\n      favorites {\n        ...userFavoritesFields\n      }\n    }\n  }\n"])), userFavoritesFields);
export var DELETE_FAVORITE_MUTATION = gql(_templateObject20 || (_templateObject20 = _taggedTemplateLiteral(["\n  ", "\n  mutation DeleteFavorite($id: Int!) {\n    deleteFavorite(id: $id) {\n      result\n      favorites {\n        ...userFavoritesFields\n      }\n    }\n  }\n"])), userFavoritesFields);
export var GET_FAVORITE_QUERY = gql(_templateObject21 || (_templateObject21 = _taggedTemplateLiteral(["\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  query FavoritesByContentType($type: String, $offset: Int, $limit: Int) {\n    favorites: favoritesByContentType(\n      type: $type\n      offset: $offset\n      limit: $limit\n    ) {\n      data {\n        ... on EpisodeMeta {\n          ...episodeMetaFields\n        }\n        ... on LiveMeta {\n          ...liveMetaFields\n        }\n        ... on TVShowMeta {\n          ...tvShowMetaFields\n        }\n        ... on ChannelMeta {\n          ...channelMetaFields\n        }\n      }\n      pageInfo {\n        ...pageInfoFields\n      }\n    }\n  }\n"])), episodeMetaFields, liveMetaFields, tvShowMetaFields, channelMetaFields, pageInfoFields);
export var VIEWING_HISTORIES = gql(_templateObject22 || (_templateObject22 = _taggedTemplateLiteral(["\n  ", "\n  ", "\n  ", "\n  query ViewingHistories(\n    $offset: Int\n    $limit: Int\n    $sort: String\n    $nocache: Boolean = false\n  ) {\n    viewingHistories(\n      offset: $offset\n      limit: $limit\n      sort: $sort\n      nocache: $nocache\n    ) {\n      data {\n        ... on EpisodeMeta {\n          ...episodeMetaFields\n          resume_point\n          last_viewing_at\n        }\n        ... on LiveMeta {\n          ...liveMetaFields\n          resume_point\n          last_viewing_at\n        }\n      }\n      pageInfo {\n        ...pageInfoFields\n      }\n    }\n  }\n"])), episodeMetaFields, liveMetaFields, pageInfoFields);
export var DELETE_VIEWING_HISTORY = gql(_templateObject23 || (_templateObject23 = _taggedTemplateLiteral(["\n  mutation DeleteViewingHistory($metaIds: [String]) {\n    deleteViewingHistory(metaIds: $metaIds) {\n      result\n    }\n  }\n"])));
export var ADD_RESUME_MUTATION = gql(_templateObject24 || (_templateObject24 = _taggedTemplateLiteral(["\n  mutation AddResume(\n    $resume: Int\n    $metaId: Int\n    $groupedMetaId: Int\n    $psid: String\n    $reachToEnd: Boolean\n  ) {\n    addResume(\n      resume: $resume\n      metaId: $metaId\n      groupedMetaId: $groupedMetaId\n      psid: $psid\n      reachToEnd: $reachToEnd\n    ) {\n      result\n    }\n  }\n"])));
export var ACCOUNT_UPDATE_MUTATION = gql(_templateObject25 || (_templateObject25 = _taggedTemplateLiteral(["\n  ", "\n  mutation AccountUpdate($mailAddress: String, $code: String) {\n    update(mailAddress: $mailAddress, code: $code) {\n      user {\n        ...accountFields\n      }\n      error\n    }\n  }\n"])), accountFields);
export var ACCOUNT_UPDATE_PASSWORD_MUTATION = gql(_templateObject26 || (_templateObject26 = _taggedTemplateLiteral(["\n  ", "\n  mutation AccountUpdatePassword($currentPassword: String, $password: String) {\n    updatePassword(currentPassword: $currentPassword, password: $password) {\n      user {\n        ...accountFields\n      }\n      error\n    }\n  }\n"])), accountFields);
export var ACCOUNT_UPDATE_OWNER_PROFILE_MUTATION = gql(_templateObject27 || (_templateObject27 = _taggedTemplateLiteral(["\n  ", "\n  mutation AccountUpdateOwnerProfile(\n    $displayName: String\n    $icon: Int\n    $passcode: String\n    $rating: Int\n  ) {\n    updateOwnerProfile(\n      displayName: $displayName\n      icon: $icon\n      passcode: $passcode\n      rating: $rating\n    ) {\n      user {\n        ...accountFields\n      }\n      error\n    }\n  }\n"])), accountFields);
export var ACCOUNT_ADD_PROFILE_MUTATION = gql(_templateObject28 || (_templateObject28 = _taggedTemplateLiteral(["\n  ", "\n  mutation AccountAddProfile(\n    $displayName: String\n    $birthday: String\n    $sex: String\n    $icon: Int\n    $rating: Int\n    $passcode: String\n  ) {\n    addProfile(\n      displayName: $displayName\n      birthday: $birthday\n      sex: $sex\n      icon: $icon\n      rating: $rating\n      passcode: $passcode\n    ) {\n      profile {\n        ...profileFields\n      }\n    }\n  }\n"])), profileFields);
export var ACCOUNT_UPDATE_PROFILE_MUTATION = gql(_templateObject29 || (_templateObject29 = _taggedTemplateLiteral(["\n  ", "\n  mutation AccountUpdateProfile(\n    $displayName: String\n    $birthday: String\n    $sex: String\n    $password: String\n    $profileId: Int\n    $icon: Int\n    $rating: Int\n    $passcode: String\n  ) {\n    updateProfile(\n      displayName: $displayName\n      birthday: $birthday\n      sex: $sex\n      password: $password\n      profileId: $profileId\n      icon: $icon\n      rating: $rating\n      passcode: $passcode\n    ) {\n      profile {\n        ...profileFields\n      }\n    }\n  }\n"])), profileFields);
export var ACCOUNT_DELETE_PROFILE_MUTATION = gql(_templateObject30 || (_templateObject30 = _taggedTemplateLiteral(["\n  mutation AccountDeleteProfile($profileId: Int) {\n    deleteProfile(profileId: $profileId) {\n      result\n    }\n  }\n"])));
export var ACCOUNT_USER_DELETE = gql(_templateObject31 || (_templateObject31 = _taggedTemplateLiteral(["\n  mutation AccountUserDelete {\n    userDelete {\n      result\n    }\n  }\n"])));
export var BROADCAST_DISCOUNT = gql(_templateObject32 || (_templateObject32 = _taggedTemplateLiteral(["\n  query AccountBroadcastDiscount($courseId: Int, $productId: Int) {\n    broadcastDiscount(courseId: $courseId, productId: $productId) {\n      result\n      user_voucher_id\n    }\n  }\n"])));
export var USER_ENTITLEMENTS = gql(_templateObject33 || (_templateObject33 = _taggedTemplateLiteral(["\n  ", "\n  query UserEntitlements {\n    entitlements: userEntitlements {\n      ...entitlementFields\n    }\n  }\n"])), entitlementFields);
export var ACCOUNT_ADVERTISING_PREFERENCES_MUTATION = gql(_templateObject34 || (_templateObject34 = _taggedTemplateLiteral(["\n  mutation AccountAdvertisingPreferences(\n    $receiveAdvertisingMailFlag: Boolean\n    $adultFlag: Boolean\n  ) {\n    advertisingPreferences(\n      receiveAdvertisingMailFlag: $receiveAdvertisingMailFlag\n      adultFlag: $adultFlag\n    ) {\n      request_id\n    }\n  }\n"])));
export var CHECK_TOKEN_TIMEOUT = gql(_templateObject35 || (_templateObject35 = _taggedTemplateLiteral(["\n  query CheckTokenTimeout {\n    checkTokenTimeout {\n      result\n    }\n  }\n"])));
export var REFRESH_TOKEN = gql(_templateObject36 || (_templateObject36 = _taggedTemplateLiteral(["\n  mutation RefreshToken {\n    refreshToken {\n      result\n    }\n  }\n"])));