import { useEffect, useState } from "react";

// browser API methods
var apiMap = [["requestFullscreen", "exitFullscreen", "fullscreenElement", "fullscreenEnabled", "fullscreenchange", "fullscreenerror"],
// WebKit
["webkitRequestFullscreen", "webkitExitFullscreen", "webkitFullscreenElement", "webkitFullscreenEnabled", "webkitfullscreenchange", "webkitfullscreenerror"],
// Old WebKit (Safari 5.1)
["webkitRequestFullScreen", "webkitCancelFullScreen", "webkitCurrentFullScreenElement", "webkitCancelFullScreen", "webkitfullscreenchange", "webkitfullscreenerror"],
// Mozilla
["mozRequestFullScreen", "mozCancelFullScreen", "mozFullScreenElement", "mozFullScreenEnabled", "mozfullscreenchange", "mozfullscreenerror"],
// Microsoft
["msRequestFullscreen", "msExitFullscreen", "msFullscreenElement", "msFullscreenEnabled", "MSFullscreenChange", "MSFullscreenError"]];
export function useFullscreenApi() {
  var _useState = useState({}),
    fullscreenAPI = _useState[0],
    setFullscreenAPI = _useState[1];
  useEffect(function () {
    var specApi = apiMap[0];
    var browserApi;

    // determine the supported set of functions
    for (var i = 0; i < apiMap.length; i++) {
      // check for exitFullscreen function
      if (apiMap[i][1] in document) {
        browserApi = apiMap[i];
        break;
      }
    }

    // map the browser API names to the spec API names
    if (browserApi) {
      var api = {};
      for (var _i = 0; _i < browserApi.length; _i++) {
        api[specApi[_i]] = browserApi[_i];
      }
      setFullscreenAPI(api);
    }
  }, []);
  return fullscreenAPI;
}