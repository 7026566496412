import { useRouter } from "next/router";
import { useEffect, useState } from "react";
export function useRouteChangeStatus() {
  var router = useRouter();
  var _useState = useState(false),
    isRouting = _useState[0],
    setIsRouting = _useState[1];
  useEffect(function () {
    var handleStart = function handleStart() {
      setIsRouting(true);
    };
    var handleComplete = function handleComplete() {
      setIsRouting(false);
    };
    var handleError = function handleError() {
      setIsRouting(false);
    };
    router.events.on("routeChangeStart", handleStart);
    router.events.on("routeChangeComplete", handleComplete);
    router.events.on("routeChangeError", handleError);
    return function () {
      router.events.off("routeChangeStart", handleStart);
      router.events.off("routeChangeComplete", handleComplete);
      router.events.off("routeChangeError", handleError);
    };
  }, [router.events]);
  return {
    isRouting: isRouting
  };
}