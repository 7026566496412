import _defineProperty from "/build/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import { useMemo, useEffect, useState } from "react";
import classnames from "classnames";
import { useDeviceInfoQuery } from "@/hooks";
import styles from "@/styles/Card.module.scss";
export function useSliderPosition(_ref, cardRef) {
  var _classnames;
  var index = _ref.index,
    focusIndex = _ref.focusIndex,
    slidesPerView = _ref.slidesPerView,
    activeIndex = _ref.activeIndex;
  var _useState = useState(false),
    focus = _useState[0],
    setFocus = _useState[1];
  var _useDeviceInfoQuery = useDeviceInfoQuery({
      fetchPolicy: "cache-first"
    }),
    data = _useDeviceInfoQuery.data;
  var deviceInfo = data === null || data === void 0 ? void 0 : data.deviceInfo;
  var active = useMemo(function () {
    if (deviceInfo !== null && deviceInfo !== void 0 && deviceInfo.isMobile || deviceInfo !== null && deviceInfo !== void 0 && deviceInfo.isTablet) return false;
    return index >= activeIndex && index < activeIndex + slidesPerView;
  }, [index, activeIndex, slidesPerView, deviceInfo === null || deviceInfo === void 0 ? void 0 : deviceInfo.isMobile, deviceInfo === null || deviceInfo === void 0 ? void 0 : deviceInfo.isTablet]);
  var feature = useMemo(function () {
    return active && index === focusIndex;
  }, [index, focusIndex, activeIndex, active]);
  var leftEdge = useMemo(function () {
    return active && index === activeIndex;
  }, [index, slidesPerView, activeIndex, active]);
  var rightEdge = useMemo(function () {
    return active && index === activeIndex + slidesPerView - 1;
  }, [index, slidesPerView, activeIndex, active]);
  useEffect(function () {
    var transitionStart = function transitionStart() {
      return setFocus(true);
    };
    var transitionEnd = function transitionEnd() {
      return !feature && setFocus(false);
    };
    if (cardRef.current) {
      cardRef.current.addEventListener("transitionstart", transitionStart);
      cardRef.current.addEventListener("transitionend", transitionEnd);
    }
    return function () {
      if (cardRef.current) {
        cardRef.current.removeEventListener("transitionstart", transitionStart);
        cardRef.current.removeEventListener("transitionend", transitionEnd);
      }
    };
  }, [feature, cardRef.current]);
  var classNames = classnames(styles.container, (_classnames = {}, _defineProperty(_classnames, styles.focus, focus), _defineProperty(_classnames, styles.feature, feature), _defineProperty(_classnames, styles.leftEdge, leftEdge), _defineProperty(_classnames, styles.rightEdge, rightEdge), _classnames));
  return {
    classNames: classNames,
    active: active
  };
}