import { useAdultModeQuery } from "@/hooks";
import routes from "@/lib/routes";
import { useRouter } from "next/router";
import { useLayoutEffect } from "react";
export function useNormalSiteOnly() {
  var router = useRouter();
  var _useAdultModeQuery = useAdultModeQuery({
      fetchPolicy: "cache-first"
    }),
    adultMode = _useAdultModeQuery.data.adultMode;
  useLayoutEffect(function () {
    if (adultMode) {
      router.replace(routes["404"]);
    }
  }, [adultMode]);
}
export function useAdultSiteOnly() {
  var router = useRouter();
  var _useAdultModeQuery2 = useAdultModeQuery({
      fetchPolicy: "cache-first"
    }),
    adultMode = _useAdultModeQuery2.data.adultMode;
  useLayoutEffect(function () {
    if (!adultMode) {
      router.replace(routes["404"]);
    }
  }, [adultMode]);
}