import Attributes from "./attributes";
import Canvas from "./canvas";
import Courses from "./courses";
import Entitlements from "./entitlements";
import Episodes from "./episodes";
import HtmlSnippet from "./htmlSnippet";
import LinkSet from "./linkSet";
import Meta from "./meta";
import MySkp from "./myskp";
import Order from "./order";
import Palette from "./palette";
import PaymentInstruments from "./paymentInstruments";
import PaymentMethods from "./paymentMethods";
import PinManager from "./pinManager";
import PlaybackAuth from "./playbackAuth";
import PlaybackSession from "./playbackSession";
import Product from "./product";
import Purchase from "./purchase";
import Recaptcha from "./recaptcha";
import Right from "./right";
import Rtoaster from "./rtoaster";
import SearchConfig from "./searchConfig";
import Subscribe from "./subscribe";
import Terms from "./terms";
import User from "./user";
import VoucherCode from "./voucherCode";
import WebPage from "./webPage";
export default function createDataSources(context) {
  return {
    user: new User(context),
    htmlSnippet: new HtmlSnippet(context),
    webPage: new WebPage(context),
    palette: new Palette(context),
    paymentInstruments: new PaymentInstruments(context),
    paymentMethods: new PaymentMethods(context),
    purchase: new Purchase(context),
    subscribe: new Subscribe(context),
    courses: new Courses(context),
    episodes: new Episodes(context),
    entitlements: new Entitlements(context),
    meta: new Meta(context),
    playbackAuth: new PlaybackAuth(context),
    playbackSession: new PlaybackSession(context),
    orders: new Order(context),
    product: new Product(context),
    terms: new Terms(context),
    canvas: new Canvas(context),
    right: new Right(context),
    linkSet: new LinkSet(context),
    attributes: new Attributes(context),
    myskp: new MySkp(context),
    voucherCode: new VoucherCode(context),
    rtoaster: new Rtoaster(context),
    searchConfig: new SearchConfig(context),
    recaptcha: new Recaptcha(context),
    pinManager: new PinManager(context)
  };
}