import "intersection-observer";
import { useEffect, useState } from "react";
export function useIntersectionObserver(ref, opts) {
  var _useState = useState(false),
    intersect = _useState[0],
    setIntersect = _useState[1];
  useEffect(function () {
    var mounted = true;
    function callback(entries) {
      if (mounted) {
        entries.forEach(function (entry) {
          setIntersect(entry.isIntersecting);
        });
      }
    }
    var observer = new IntersectionObserver(callback, opts);
    if (!ref.current) {
      return;
    }
    observer.observe(ref.current);
    return function () {
      mounted = false;
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref.current]);
  return intersect;
}