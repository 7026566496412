import _taggedTemplateLiteral from "/build/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject;
import { gql } from "@apollo/client";
import { values } from "./helper";
export var typeDef = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  type Advert {\n    id: ID!\n    name: String\n    description: String\n    url: String\n    targetBlank: Boolean\n    sort: Int\n    thumbnailUrl: String\n    copyright: String\n  }\n"])));
export var resolvers = {
  Advert: {
    name: function name(ad) {
      return ad.display_name;
    },
    targetBlank: function targetBlank(ad) {
      return values(ad, "target_blank");
    },
    sort: function sort(ad) {
      return values(ad, "sort");
    },
    thumbnailUrl: function thumbnailUrl(ad) {
      var _ad$creatives;
      return ad === null || ad === void 0 || (_ad$creatives = ad.creatives) === null || _ad$creatives === void 0 || (_ad$creatives = _ad$creatives[0]) === null || _ad$creatives === void 0 ? void 0 : _ad$creatives.url;
    },
    copyright: function copyright(ad) {
      var _ad$creatives2;
      return ad === null || ad === void 0 || (_ad$creatives2 = ad.creatives) === null || _ad$creatives2 === void 0 || (_ad$creatives2 = _ad$creatives2[0]) === null || _ad$creatives2 === void 0 ? void 0 : _ad$creatives2.copyright;
    }
  }
};