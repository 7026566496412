import _taggedTemplateLiteral from "/build/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject;
import { gql } from "@apollo/client";
import { dateFormat, values } from "./helper";
export var typeDef = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  type Campaign {\n    id: ID\n    name: String\n    header: String\n    description: String\n    validityStartAt: String\n    validityEndAt: String\n    broadcastDiscount: Boolean\n    discountApplyCount: Int\n  }\n"])));
export var resolvers = {
  Campaign: {
    id: function id(c) {
      return c.campaign_id;
    },
    broadcastDiscount: function broadcastDiscount(c) {
      var _c$values;
      return c === null || c === void 0 || (_c$values = c.values) === null || _c$values === void 0 ? void 0 : _c$values.is_broadcast_discount;
    },
    validityStartAt: function validityStartAt(c) {
      return dateFormat(c.validity_start_at);
    },
    validityEndAt: function validityEndAt(c) {
      return dateFormat(c.validity_end_at);
    },
    discountApplyCount: function discountApplyCount(c) {
      return values(c, "discount_apply_count");
    }
  }
};