import { useEffect, useCallback } from "react";
export function usePreventContextMenu() {
  var on = useCallback(function () {
    document.body.oncontextmenu = function () {
      return false;
    };
    document.body.ondragstart = function () {
      return false;
    };
  }, []);
  var off = useCallback(function () {
    document.body.oncontextmenu = "";
    document.body.ondragstart = "";
  }, []);
  useEffect(function () {
    on();
  }, []);
  return [on, off];
}