"use strict";

import _ from "lodash";
var getIpAddress = function getIpAddress(ip) {
  if (!ip) return;
  return _.head(_.uniq(_.compact(_.replace(ip, "　", " ").split(/[,\s]+/))));
};
export default function getRemoteIpAddress(req) {
  var remoteIp = getIpAddress(req.headers["x-forwarded-for"]);
  if (remoteIp) {
    return remoteIp;
  }
  remoteIp = getIpAddress(req.headers["x-real-ip"]);
  if (remoteIp) {
    return remoteIp;
  }
  remoteIp = getIpAddress(req.headers["remote_addr"]);
  if (remoteIp) {
    return remoteIp;
  }
  if (req.connection) {
    remoteIp = getIpAddress(req.connection.remoteAddress);
    if (remoteIp) {
      return remoteIp;
    }
  }
  if (req.socket) {
    remoteIp = getIpAddress(req.socket.remoteAddress);
    if (remoteIp) {
      return remoteIp;
    }
  }
  return "0.0.0.0";
}