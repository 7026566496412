import config from "@/lib/config";
export default {
  401: "/401",
  404: "/404",
  geoRestriction: "/geo_restriction",
  home: "/",
  signUp: "/signup",
  signupStep: function signupStep(step) {
    return "/signup/step".concat(step);
  },
  subscriptionByRight: function subscriptionByRight(id) {
    return "/subscription/right/".concat(id);
  },
  svodPayment: function svodPayment(courseId) {
    return "/subscription/".concat(courseId);
  },
  svodPaymentStep: function svodPaymentStep(courseId, step) {
    return "/subscription/".concat(courseId, "/step").concat(step);
  },
  subscriptionNotAllowed: "/subscription/notallowed",
  subscriptionContracted: "/subscription/contracted",
  signupComplete: "/signup/complete",
  login: "/login",
  loginWhileDeviceActivation: function loginWhileDeviceActivation(activationCode) {
    return "/login?code=".concat(activationCode);
  },
  logout: "/logout",
  passwordRemind: "/password_remind",
  passwordRemindReset: "/password_remind/reset",
  profilePincodeReset: "/profile_pincode_reset",
  restricted: "/restricted",
  title: function title(id) {
    return "/title/".concat(id);
  },
  titleWithRtsid: function titleWithRtsid(item) {
    if (item !== null && item !== void 0 && item.rtsid) {
      return "/title/".concat(item.id, "?rtsid=").concat(item.rtsid);
    } else {
      return "/title/".concat(item.id);
    }
  },
  titleEpisodes: function titleEpisodes(id) {
    return "/title/".concat(id, "#episodes");
  },
  titleRecommend: function titleRecommend(id) {
    return "/title/".concat(id, "#recommend");
  },
  channels: "/channels",
  channelDetail: function channelDetail(id, hash) {
    if (hash) {
      return "/channels/".concat(id, "#").concat(hash);
    } else {
      return "/channels/".concat(id);
    }
  },
  channelDetailSearch: function channelDetailSearch(id, query) {
    return "/channels/".concat(id, "/search?q=").concat(encodeURIComponent(query));
  },
  channelDetailSearchOnair: function channelDetailSearchOnair(id, query) {
    return "/channels/".concat(id, "/search/onair?q=").concat(encodeURIComponent(query));
  },
  channelDetailSearchBefore: function channelDetailSearchBefore(id, query) {
    return "/channels/".concat(id, "/search/before?q=").concat(encodeURIComponent(query));
  },
  channelDetailSearchCatchup: function channelDetailSearchCatchup(id, query) {
    return "/channels/".concat(id, "/search/catchup?q=").concat(encodeURIComponent(query));
  },
  channelDetailSearchVideo: function channelDetailSearchVideo(id, query) {
    return "/channels/".concat(id, "/search/video?q=").concat(encodeURIComponent(query));
  },
  channelDetailFeatured: function channelDetailFeatured(id) {
    return "/channels/".concat(id, "/featured");
  },
  channelsEntitled: "/channels#entitled",
  channelsUnentitled: "/channels#unentitled",
  valuePlan: "/valueplan",
  valuePlanDiscountDetail: "/static/sales/campaign/valueplan_with_kihon",
  valuePlanCampaignDetail: "/static/sales/campaign/valueplan90",
  store: "/store",
  myList: "/mylist",
  myListFavorites: "/mylist#favorites",
  myListValuePlan: "/mylist#valueplan",
  myListLive: "/mylist/favorites/live",
  myListEpisode: "/mylist/favorites/episode",
  myListTvShow: "/mylist/favorites/tvshow",
  myListChannel: "/mylist/favorites/channel",
  myListSubscription: "/mylist#subscription",
  myListRental: "/mylist#rental",
  myListWatchHistory: "/mylist#watchhistory",
  profileAdd: "/profiles/add",
  profileEdit: function profileEdit(id) {
    return "/profiles/edit/".concat(id);
  },
  profileSwitch: function profileSwitch(prev) {
    return prev ? "/profiles/switch?prev=1" : "/profiles/switch";
  },
  tvodPayment: function tvodPayment(productId) {
    return "/purchase/".concat(productId);
  },
  tvodPaymentStep: function tvodPaymentStep(productId, step) {
    return "/purchase/".concat(productId, "/step").concat(step);
  },
  productPurchased: "/purchase/purchased_error",
  purchaseError: "/purchase/error",
  sbpsSuccess: "/relation/success",
  sbpsError: "/relation/error",
  watch: function watch(id) {
    return "/watch/".concat(id);
  },
  watchWithRtsid: function watchWithRtsid(item) {
    if (item !== null && item !== void 0 && item.rtsid) {
      return "/watch/".concat(item.id, "?rtsid=").concat(item.rtsid);
    } else {
      return "/watch/".concat(item.id);
    }
  },
  account: "/account",
  accountProduct: "/account#product",
  accountPaymentMethod: "/account#paymentmethod",
  accountPaymentHistory: "/account#paymenthistory",
  accountAddPayment: "/account/addpayment",
  accountInfoEdit: "/account/edit",
  accountPassword: "/account/password",
  accountChangeSvodPayment: function accountChangeSvodPayment(subscriptionId) {
    return "/account/subscription_payment/".concat(subscriptionId);
  },
  palette: function palette(id) {
    return "/palette/".concat(id);
  },
  unsubscribe: function unsubscribe(courseId) {
    return "/account/unsubscribe/".concat(courseId);
  },
  unsubscribeStep: function unsubscribeStep(courseId, step) {
    return "/account/unsubscribe/".concat(courseId, "/step").concat(step);
  },
  unsubscribeNoContract: "/account/unsubscribe/nocontract",
  unsubscribeError: "/account/unsubscribe/error",
  withdraw: "/account/withdraw",
  withdrawInvoke: "/account/withdraw/invoke",
  withdrawComplete: "/account/withdraw/complete",
  withdrawForbidden: "/account/withdraw/forbidden",
  withdrawError: "/account/withdraw/error",
  inquiry: "/inquiry",
  inquiryStep: function inquiryStep(step) {
    return "/inquiry/step".concat(step);
  },
  search: function search(query, path) {
    if (path) {
      return "/search/".concat(path, "?q=").concat(encodeURIComponent(query));
    } else {
      return "/search?q=".concat(encodeURIComponent(query));
    }
  },
  informations: "/informations",
  myskpLogin: "/myskp/login",
  myskpLoginWhileDeviceActivation: function myskpLoginWhileDeviceActivation(activationCode) {
    return "/myskp/login?code=".concat(activationCode);
  },
  myskpSignupLogin: "/myskp/signup/login",
  myskpSignUp: function myskpSignUp(token) {
    return "/myskp/signup?t=".concat(token);
  },
  myskpSignupComplete: function myskpSignupComplete(hash) {
    if (hash) {
      return "/myskp/signup/complete#".concat(hash);
    } else {
      return "/myskp/signup/complete";
    }
  },
  myskpTokenInvalid: "/myskp/token_invalid",
  myskpCanceled: "/myskp/canceled",
  myskpExists: "/myskp/exists",
  myskpRelation: function myskpRelation(token) {
    return "/myskp/relation?t=".concat(token);
  },
  myskpRelationComplete: function myskpRelationComplete(hash) {
    if (hash) {
      return "/myskp/relation/complete#".concat(hash);
    } else {
      return "/myskp/relation/complete";
    }
  },
  myskpRelationFail: "/myskp/relation/fail",
  mySkpRelationAuth: "/myskp/relation/auth",
  campaignWithBroadcast: "/static/sales/campaign/with_broadcast",
  environment: "/static/feature/constant/environment",
  legalSctLaw: "/static/legal/sct_law",
  adultEx: "/channels/".concat(config.adultEx),
  canvas: function canvas(slug) {
    return "/canvas/".concat(slug);
  },
  deviceActivation: function deviceActivation(activationCode) {
    return "/device/activation?code=".concat(activationCode);
  },
  activationSuccess: "/device/activation/success",
  activationError: "/device/activation/error",
  mailOption: "/account/mail_option",
  timeOut: "/timeout"
};