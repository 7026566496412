import { InMemoryCache } from "@apollo/client";
function offsetLimitPageInfoPagination() {
  var keyArgs = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  return {
    keyArgs: keyArgs,
    merge: function merge(existing, incoming, _ref) {
      var _existing, _incoming;
      var args = _ref.args;
      existing = (_existing = existing) !== null && _existing !== void 0 ? _existing : {
        data: [],
        pageInfo: {}
      };
      incoming = (_incoming = incoming) !== null && _incoming !== void 0 ? _incoming : {
        data: [],
        pageInfo: {}
      };
      var _existing2 = existing,
        existingData = _existing2.data,
        existingPageInfo = _existing2.pageInfo;
      var _incoming2 = incoming,
        incomingData = _incoming2.data,
        incomingPageInfo = _incoming2.pageInfo;
      var merged = existingData ? existingData.slice(0) : [];
      if (args) {
        var _args$offset = args.offset,
          offset = _args$offset === void 0 ? 0 : _args$offset,
          limit = args.limit;
        merged = existingData ? existingData.slice(0, (offset + 1) * limit) : [];
        for (var i = 0; i < incomingData.length; ++i) {
          merged[offset + i] = incomingData[i];
        }
      } else {
        merged = merged.concat(incomingData);
      }
      var pageInfo = function () {
        if (args) {
          var _args$offset2 = args.offset,
            _offset = _args$offset2 === void 0 ? 0 : _args$offset2;
          if (_offset === (incomingPageInfo === null || incomingPageInfo === void 0 ? void 0 : incomingPageInfo.offset)) {
            return incomingPageInfo;
          } else if (_offset === (existingPageInfo === null || existingPageInfo === void 0 ? void 0 : existingPageInfo.offset)) {
            return existingPageInfo;
          }
        } else {
          return incomingPageInfo;
        }
      }();
      return {
        data: merged,
        pageInfo: pageInfo
      };
    }
  };
}
var cache = function cache() {
  return new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          channels: offsetLimitPageInfoPagination(["filter", "sort"]),
          channelsWithContractStatus: offsetLimitPageInfoPagination(["filter", "sort", "uuid"]),
          channelFeatureEpisodes: offsetLimitPageInfoPagination(["id"]),
          storeEpisodes: offsetLimitPageInfoPagination(["filter", "sort"]),
          paletteObjects: offsetLimitPageInfoPagination(["id"]),
          children: offsetLimitPageInfoPagination(["id", "filter", "sort"]),
          canvas: offsetLimitPageInfoPagination(["idKey"]),
          channelEpisodeSearch: offsetLimitPageInfoPagination(["id", "keyword", "episodeType"]),
          channelLiveSchedule: offsetLimitPageInfoPagination(["id", "day"]),
          channelLinearEpisodes: offsetLimitPageInfoPagination(["id"]),
          search: offsetLimitPageInfoPagination(["keyword", "filter", "sort"]),
          searchTvShow: offsetLimitPageInfoPagination(["keyword", "filter", "sort"]),
          informations: offsetLimitPageInfoPagination(["key"]),
          favoritesByContentType: offsetLimitPageInfoPagination(["type"]),
          getEntitledStoreEpisodes: offsetLimitPageInfoPagination(),
          viewingHistories: offsetLimitPageInfoPagination(["sort"]),
          orders: offsetLimitPageInfoPagination(),
          genrePaletteEpisodes: offsetLimitPageInfoPagination(["channel", "genre", "sortOrder", "type"]),
          titles: offsetLimitPageInfoPagination(["filter", "sort"])
        }
      },
      AccountUser: {
        keyFields: ["uuid"]
      },
      Palette: {
        keyFields: ["palette_id"]
      },
      PaletteObject: {
        keyFields: ["type", "id"]
      },
      Term: {
        keyFields: ["id_key"]
      },
      TermRevision: {
        keyFields: ["unique_key"]
      },
      TermAgreement: {
        keyFields: ["agreement_id"]
      },
      CanvasStructure: {
        keyFields: ["type", "id"]
      }
    }
  });
};
export default cache;