import config from "@/lib/config";
import { PAYMENT_METHOD_ID } from "@/lib/constants";
import routes from "@/lib/routes";
var footer = {
  vod: "\n\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\n\u25A1\u3053\u306E\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9\u306F\u3001\u914D\u4FE1\u5C02\u7528\u3067\u3059\u3002\n  \u672C\u30E1\u30FC\u30EB\u306B\u8FD4\u4FE1\u3092\u3044\u305F\u3060\u304D\u307E\u3057\u3066\u3082\u3001\u3054\u8CEA\u554F\u7B49\u306B\u306F\u304A\u7B54\u3048\u3067\u304D\u307E\u305B\u3093\u306E\u3067\u3054\u4E86\u627F\u304F\u3060\u3055\u3044\u3002\n\n\u25A1\u304A\u554F\u3044\u5408\u308F\u305B\u30D5\u30A9\u30FC\u30E0\n  https://spoox.skyperfectv.co.jp/inquiry\n\n\u25A1\u914D\u4FE1\u5143\n  \u30B9\u30AB\u30D1\u30FCJSAT\u682A\u5F0F\u4F1A\u793E\u3000\u6771\u4EAC\u90FD\u6E2F\u533A\u8D64\u57421-8-1\n\n\u25A1SPOOX\u516C\u5F0F\u30B5\u30A4\u30C8\n  https://spoox.skyperfectv.co.jp/\n\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\n",
  adult: "\n\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\n\u25A1\u3053\u306E\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9\u306F\u3001\u914D\u4FE1\u5C02\u7528\u3067\u3059\u3002\n\u3000\u672C\u30E1\u30FC\u30EB\u306B\u8FD4\u4FE1\u3092\u3044\u305F\u3060\u304D\u307E\u3057\u3066\u3082\u3001\u3054\u8CEA\u554F\u7B49\u306B\u306F\u304A\u7B54\u3048\u3067\u304D\u307E\u305B\u3093\u306E\u3067\u3054\u4E86\u627F\u304F\u3060\u3055\u3044\u3002\n\n\u25A1\u304A\u554F\u3044\u5408\u308F\u305B\u30D5\u30A9\u30FC\u30E0\n  https://spoox-ex.skyperfectv.co.jp/inquiry\n\n\u25A1\u914D\u4FE1\u5143\n  \u30B9\u30AB\u30D1\u30FC\u30FB\u30D6\u30ED\u30FC\u30C9\u30AD\u30E3\u30B9\u30C6\u30A3\u30F3\u30B0\u682A\u5F0F\u4F1A\u793E\n\n\u25A1SPOOX EX\u516C\u5F0F\u30B5\u30A4\u30C8\n  https://spoox-ex.skyperfectv.co.jp/\n\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\uFF65\n  "
};
function adultOrVod(adultMode) {
  return adultMode ? "adult" : "vod";
}
export function registerPincodeMail(_ref, adultMode) {
  var address = _ref.address,
    pincode = _ref.pincode;
  var maxAge = config.pincode.maxAge / 60;
  var serviceName = config.serviceName[adultOrVod(adultMode)];
  var body = {
    vod: "\n\u3053\u306E\u5EA6\u306F".concat(serviceName, "\u3092\u3054\u5229\u7528\u3044\u305F\u3060\u304D\u3042\u308A\u304C\u3068\u3046\u3054\u3056\u3044\u307E\u3059\u3002\n\u672C\u4EBA\u78BA\u8A8D\u306E\u305F\u3081\u3001\u4EE5\u4E0B\u306E\u8A8D\u8A3C\u30B3\u30FC\u30C9\u3092\u5165\u529B\u3057\u4F1A\u54E1\u767B\u9332\u3092\u304A\u9858\u3044\u3057\u307E\u3059\u3002\n\u8A8D\u8A3C\u30B3\u30FC\u30C9\uFF1A").concat(pincode, "\n\u203B\u8A8D\u8A3C\u30B3\u30FC\u30C9\u306E\u6709\u52B9\u671F\u9650\u306F\u304A\u9001\u308A\u3057\u3066\u304B\u3089").concat(maxAge, "\u5206\u3067\u3059\u3002\n\u203B\u6709\u52B9\u671F\u9650\u304C\u5207\u308C\u305F\u969B\u306B\u306F\u518D\u5EA6\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9\u3092\u5165\u529B\u3057\u3066\u518D\u9001\u4FE1\u3057\u3066\u304F\u3060\u3055\u3044\u3002\n\n\u3053\u306E\u30E1\u30FC\u30EB\u306B\u304A\u5FC3\u3042\u305F\u308A\u306E\u306A\u3044\u5834\u5408\u306F\u3001\u304A\u624B\u6570\u3067\u3059\u304C\u3001\u524A\u9664\u3057\u3066\u3044\u305F\u3060\u304D\u307E\u3059\u3088\u3046\u304A\u9858\u3044\u3044\u305F\u3057\u307E\u3059\u3002\n\n").concat(footer.vod, "\n  "),
    adult: "\n\u3053\u306E\u5EA6\u306F".concat(serviceName, "\u3092\u3054\u5229\u7528\u3044\u305F\u3060\u304D\u3042\u308A\u304C\u3068\u3046\u3054\u3056\u3044\u307E\u3059\u3002\n\u672C\u4EBA\u78BA\u8A8D\u306E\u305F\u3081\u3001\u4EE5\u4E0B\u306E\u8A8D\u8A3C\u30B3\u30FC\u30C9\u3092\u5165\u529B\u3057\u4F1A\u54E1\u767B\u9332\u3092\u304A\u9858\u3044\u3057\u307E\u3059\u3002\n\u8A8D\u8A3C\u30B3\u30FC\u30C9\uFF1A").concat(pincode, "\n\u203B\u8A8D\u8A3C\u30B3\u30FC\u30C9\u306E\u6709\u52B9\u671F\u9650\u306F\u304A\u9001\u308A\u3057\u3066\u304B\u3089").concat(maxAge, "\u5206\u3067\u3059\u3002\n\u203B\u6709\u52B9\u671F\u9650\u304C\u5207\u308C\u305F\u969B\u306B\u306F\u518D\u5EA6\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9\u3092\u5165\u529B\u3057\u3066\u518D\u9001\u4FE1\u3057\u3066\u304F\u3060\u3055\u3044\u3002\n\n\u3053\u306E\u30E1\u30FC\u30EB\u306B\u304A\u5FC3\u3042\u305F\u308A\u306E\u306A\u3044\u5834\u5408\u306F\u3001\u304A\u624B\u6570\u3067\u3059\u304C\u3001\u524A\u9664\u3057\u3066\u3044\u305F\u3060\u304D\u307E\u3059\u3088\u3046\u304A\u9858\u3044\u3044\u305F\u3057\u307E\u3059\u3002\n\n").concat(footer.adult, "\n  ")
  };
  return {
    address: address,
    subject: "".concat(serviceName, "\u8A8D\u8A3C\u30B3\u30FC\u30C9\u9001\u4ED8\u306E\u304A\u77E5\u3089\u305B"),
    body: body[adultOrVod(adultMode)],
    adultMode: adultMode
  };
}
export function accountEditPincodeMail(_ref2, adultMode) {
  var address = _ref2.address,
    pincode = _ref2.pincode;
  var maxAge = config.pincode.maxAge / 60;
  var serviceName = config.serviceName[adultOrVod(adultMode)];
  var body = {
    vod: "\n\u3053\u306E\u5EA6\u306F".concat(serviceName, "\u3092\u3054\u5229\u7528\u3044\u305F\u3060\u304D\u3042\u308A\u304C\u3068\u3046\u3054\u3056\u3044\u307E\u3059\u3002\n\u672C\u4EBA\u78BA\u8A8D\u306E\u305F\u3081\u3001\u4EE5\u4E0B\u306E\u8A8D\u8A3C\u30B3\u30FC\u30C9\u3092\u5165\u529B\u3057\u4F1A\u54E1\u60C5\u5831\u5909\u66F4\u3092\u304A\u9858\u3044\u3057\u307E\u3059\u3002\n\u8A8D\u8A3C\u30B3\u30FC\u30C9\uFF1A").concat(pincode, "\n\u203B\u8A8D\u8A3C\u30B3\u30FC\u30C9\u306E\u6709\u52B9\u671F\u9650\u306F\u304A\u9001\u308A\u3057\u3066\u304B\u3089").concat(maxAge, "\u5206\u3067\u3059\u3002\n\u203B\u6709\u52B9\u671F\u9650\u304C\u5207\u308C\u305F\u969B\u306B\u306F\u518D\u5EA6\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9\u3092\u5165\u529B\u3057\u3066\u518D\u9001\u4FE1\u3057\u3066\u304F\u3060\u3055\u3044\u3002\n\n\u3053\u306E\u30E1\u30FC\u30EB\u306B\u304A\u5FC3\u3042\u305F\u308A\u306E\u306A\u3044\u5834\u5408\u306F\u3001\u304A\u624B\u6570\u3067\u3059\u304C\u3001\u524A\u9664\u3057\u3066\u3044\u305F\u3060\u304D\u307E\u3059\u3088\u3046\u304A\u9858\u3044\u3044\u305F\u3057\u307E\u3059\u3002\n\n").concat(footer.vod, "\n  "),
    adult: "\n\u3053\u306E\u5EA6\u306F".concat(serviceName, "\u3092\u3054\u5229\u7528\u3044\u305F\u3060\u304D\u3042\u308A\u304C\u3068\u3046\u3054\u3056\u3044\u307E\u3059\u3002\n\u672C\u4EBA\u78BA\u8A8D\u306E\u305F\u3081\u3001\u4EE5\u4E0B\u306E\u8A8D\u8A3C\u30B3\u30FC\u30C9\u3092\u5165\u529B\u3057\u4F1A\u54E1\u60C5\u5831\u5909\u66F4\u3092\u304A\u9858\u3044\u3057\u307E\u3059\u3002\n\u8A8D\u8A3C\u30B3\u30FC\u30C9\uFF1A").concat(pincode, "\n\u203B\u8A8D\u8A3C\u30B3\u30FC\u30C9\u306E\u6709\u52B9\u671F\u9650\u306F\u304A\u9001\u308A\u3057\u3066\u304B\u3089").concat(maxAge, "\u5206\u3067\u3059\u3002\n\u203B\u6709\u52B9\u671F\u9650\u304C\u5207\u308C\u305F\u969B\u306B\u306F\u518D\u5EA6\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9\u3092\u5165\u529B\u3057\u3066\u518D\u9001\u4FE1\u3057\u3066\u304F\u3060\u3055\u3044\u3002\n\n\u3053\u306E\u30E1\u30FC\u30EB\u306B\u304A\u5FC3\u3042\u305F\u308A\u306E\u306A\u3044\u5834\u5408\u306F\u3001\u304A\u624B\u6570\u3067\u3059\u304C\u3001\u524A\u9664\u3057\u3066\u3044\u305F\u3060\u304D\u307E\u3059\u3088\u3046\u304A\u9858\u3044\u3044\u305F\u3057\u307E\u3059\u3002\n\n").concat(footer.adult, "\n  ")
  };
  return {
    address: address,
    subject: "".concat(serviceName, "\u8A8D\u8A3C\u30B3\u30FC\u30C9\u9001\u4ED8\u306E\u304A\u77E5\u3089\u305B"),
    body: body[adultOrVod(adultMode)],
    adultMode: adultMode
  };
}
export function profilePinResetTokenMail(_ref3, adultMode) {
  var address = _ref3.address,
    token = _ref3.token;
  var maxAge = config.pincode.maxAge / 60;
  var url = "".concat(config.host.vod).concat(routes.profilePincodeReset, "?token=").concat(token);
  var urlAdult = "".concat(config.host.adult).concat(routes.profilePincodeReset, "?token=").concat(token);
  var serviceName = config.serviceName[adultOrVod(adultMode)];
  var body = {
    vod: "\n\u3053\u306E\u5EA6\u306F".concat(serviceName, "\u3092\u3054\u5229\u7528\u3044\u305F\u3060\u304D\u3042\u308A\u304C\u3068\u3046\u3054\u3056\u3044\u307E\u3059\u3002\n\u6697\u8A3C\u756A\u53F7\u30EA\u30BB\u30C3\u30C8\u3092\u53D7\u4ED8\u3044\u305F\u3057\u307E\u3057\u305F\u3002\n\u4EE5\u4E0BURL\u3088\u308A\u304A\u624B\u7D9A\u304D\u3092\u304A\u9858\u3044\u3044\u305F\u3057\u307E\u3059\u3002\n\n").concat(url, "\n\nURL\u304C\u767A\u884C\u3055\u308C\u3066\u304B\u3089").concat(maxAge, "\u5206\u4EE5\u5185\u306B\u767B\u9332\u3092\u5B8C\u4E86\u3057\u3066\u304F\u3060\u3055\u3044\u3002\nURL\u304C\u7121\u52B9\u306B\u306A\u3063\u305F\u5834\u5408\u306F\u3001\u304A\u624B\u6570\u3067\u3059\u304C\u518D\u5EA6\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9\u306E\u767B\u9332\u3092\u884C\u3063\u3066\u304F\u3060\u3055\u3044\u3002\n\n").concat(footer.vod, "\n  "),
    adult: "\n\u3053\u306E\u5EA6\u306F".concat(serviceName, "\u3092\u3054\u5229\u7528\u3044\u305F\u3060\u304D\u3042\u308A\u304C\u3068\u3046\u3054\u3056\u3044\u307E\u3059\u3002\n\u6697\u8A3C\u756A\u53F7\u30EA\u30BB\u30C3\u30C8\u3092\u53D7\u4ED8\u3044\u305F\u3057\u307E\u3057\u305F\u3002\n\u4EE5\u4E0BURL\u3088\u308A\u304A\u624B\u7D9A\u304D\u3092\u304A\u9858\u3044\u3044\u305F\u3057\u307E\u3059\u3002\n\n").concat(urlAdult, "\n\nURL\u304C\u767A\u884C\u3055\u308C\u3066\u304B\u3089").concat(maxAge, "\u5206\u4EE5\u5185\u306B\u767B\u9332\u3092\u5B8C\u4E86\u3057\u3066\u304F\u3060\u3055\u3044\u3002\nURL\u304C\u7121\u52B9\u306B\u306A\u3063\u305F\u5834\u5408\u306F\u3001\u304A\u624B\u6570\u3067\u3059\u304C\u518D\u5EA6\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9\u306E\u767B\u9332\u3092\u884C\u3063\u3066\u304F\u3060\u3055\u3044\u3002\n\n").concat(footer.adult, "\n  ")
  };
  return {
    address: address,
    subject: "暗証番号リセットのお知らせ",
    body: body[adultOrVod(adultMode)],
    adultMode: adultMode
  };
}
export function passwordResetTokenMail(_ref4, adultMode) {
  var address = _ref4.address,
    token = _ref4.token;
  var maxAge = config.pincode.maxAge / 60;
  var url = "".concat(config.host.vod).concat(routes.passwordRemindReset, "?token=").concat(token);
  var urlAdult = "".concat(config.host.adult).concat(routes.passwordRemindReset, "?token=").concat(token);
  var serviceName = config.serviceName[adultOrVod(adultMode)];
  var body = {
    vod: "\n\u3053\u306E\u5EA6\u306F".concat(serviceName, "\u3092\u3054\u5229\u7528\u3044\u305F\u3060\u304D\u3042\u308A\u304C\u3068\u3046\u3054\u3056\u3044\u307E\u3059\u3002\n\u30D1\u30B9\u30EF\u30FC\u30C9\u30EA\u30BB\u30C3\u30C8\u3092\u53D7\u4ED8\u3044\u305F\u3057\u307E\u3057\u305F\u3002\n\u4EE5\u4E0BURL\u3088\u308A\u304A\u624B\u7D9A\u304D\u3092\u304A\u9858\u3044\u3044\u305F\u3057\u307E\u3059\u3002\n\n").concat(url, "\n\nURL\u304C\u767A\u884C\u3055\u308C\u3066\u304B\u3089").concat(maxAge, "\u5206\u4EE5\u5185\u306B\u767B\u9332\u3092\u5B8C\u4E86\u3057\u3066\u304F\u3060\u3055\u3044\u3002\nURL\u304C\u7121\u52B9\u306B\u306A\u3063\u305F\u5834\u5408\u306F\u3001\u304A\u624B\u6570\u3067\u3059\u304C\u518D\u5EA6\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9\u306E\u767B\u9332\u3092\u884C\u3063\u3066\u304F\u3060\u3055\u3044\u3002\n\n").concat(footer.vod, "\n  "),
    adult: "\n\u3053\u306E\u5EA6\u306F".concat(serviceName, "\u3092\u3054\u5229\u7528\u3044\u305F\u3060\u304D\u3042\u308A\u304C\u3068\u3046\u3054\u3056\u3044\u307E\u3059\u3002\n\u30D1\u30B9\u30EF\u30FC\u30C9\u30EA\u30BB\u30C3\u30C8\u3092\u53D7\u4ED8\u3044\u305F\u3057\u307E\u3057\u305F\u3002\n\u4EE5\u4E0BURL\u3088\u308A\u304A\u624B\u7D9A\u304D\u3092\u304A\u9858\u3044\u3044\u305F\u3057\u307E\u3059\u3002\n\n").concat(urlAdult, "\n\nURL\u304C\u767A\u884C\u3055\u308C\u3066\u304B\u3089").concat(maxAge, "\u5206\u4EE5\u5185\u306B\u767B\u9332\u3092\u5B8C\u4E86\u3057\u3066\u304F\u3060\u3055\u3044\u3002\nURL\u304C\u7121\u52B9\u306B\u306A\u3063\u305F\u5834\u5408\u306F\u3001\u304A\u624B\u6570\u3067\u3059\u304C\u518D\u5EA6\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9\u306E\u767B\u9332\u3092\u884C\u3063\u3066\u304F\u3060\u3055\u3044\u3002\n\n").concat(footer.adult, "\n  ")
  };
  return {
    address: address,
    subject: "パスワードリセット方法のお知らせ",
    body: body[adultOrVod(adultMode)],
    adultMode: adultMode
  };
}
export function passwordResetComplete(_ref5, adultMode) {
  var address = _ref5.address;
  var serviceName = config.serviceName[adultOrVod(adultMode)];
  var body = {
    vod: "\n\u3053\u306E\u5EA6\u306F".concat(serviceName, "\u3092\u3054\u5229\u7528\u3044\u305F\u3060\u304D\u3042\u308A\u304C\u3068\u3046\u3054\u3056\u3044\u307E\u3059\u3002\n\u30D1\u30B9\u30EF\u30FC\u30C9\u30EA\u30BB\u30C3\u30C8\u304C\u5B8C\u4E86\u3044\u305F\u3057\u307E\u3057\u305F\u3002\n\u3054\u767B\u9332\u3044\u305F\u3060\u3044\u305F\u30D1\u30B9\u30EF\u30FC\u30C9\u3067\u30ED\u30B0\u30A4\u30F3\u3057\u3066\u304A\u697D\u3057\u307F\u304F\u3060\u3055\u3044\u3002\n\n").concat(config.host.vod).concat(routes.login, "\n\n").concat(footer.vod, "\n  "),
    adult: "\n\u3053\u306E\u5EA6\u306F".concat(serviceName, "\u3092\u3054\u5229\u7528\u3044\u305F\u3060\u304D\u3042\u308A\u304C\u3068\u3046\u3054\u3056\u3044\u307E\u3059\u3002\n\u30D1\u30B9\u30EF\u30FC\u30C9\u30EA\u30BB\u30C3\u30C8\u304C\u5B8C\u4E86\u3044\u305F\u3057\u307E\u3057\u305F\u3002\n\u3054\u767B\u9332\u3044\u305F\u3060\u3044\u305F\u30D1\u30B9\u30EF\u30FC\u30C9\u3067\u30ED\u30B0\u30A4\u30F3\u3057\u3066\u304A\u697D\u3057\u307F\u304F\u3060\u3055\u3044\u3002\n\n").concat(config.host.adult).concat(routes.login, "\n\n").concat(footer.adult, "\n  ")
  };
  return {
    address: address,
    subject: "パスワードリセット完了のお知らせ",
    body: body[adultOrVod(adultMode)],
    adultMode: adultMode
  };
}
export function passwordUpdateComplete(_ref6, adultMode) {
  var address = _ref6.address;
  var body = {
    vod: "\n    \u30D1\u30B9\u30EF\u30FC\u30C9\u5909\u66F4\u304C\u5B8C\u4E86\u3044\u305F\u3057\u307E\u3057\u305F\u3002\n    \u3054\u767B\u9332\u3044\u305F\u3060\u3044\u305F\u30D1\u30B9\u30EF\u30FC\u30C9\u3067\u30ED\u30B0\u30A4\u30F3\u3057\u3066\u304A\u697D\u3057\u307F\u304F\u3060\u3055\u3044\u3002\n    \u30ED\u30B0\u30A4\u30F3\n    ".concat(config.host.vod).concat(routes.login, "\n\n").concat(footer.vod, "\n  "),
    adult: "\n    \u30D1\u30B9\u30EF\u30FC\u30C9\u5909\u66F4\u304C\u5B8C\u4E86\u3044\u305F\u3057\u307E\u3057\u305F\u3002\n    \u3054\u767B\u9332\u3044\u305F\u3060\u3044\u305F\u30D1\u30B9\u30EF\u30FC\u30C9\u3067\u30ED\u30B0\u30A4\u30F3\u3057\u3066\u304A\u697D\u3057\u307F\u304F\u3060\u3055\u3044\u3002\n    \u30ED\u30B0\u30A4\u30F3\n    ".concat(config.host.adult).concat(routes.login, "\n\n").concat(footer.adult, "\n  ")
  };
  return {
    address: address,
    subject: "パスワード変更完了のお知らせ",
    body: body[adultOrVod(adultMode)],
    adultMode: adultMode
  };
}
export function duplicateAddress(_ref7, adultMode) {
  var address = _ref7.address;
  var serviceName = config.serviceName[adultOrVod(adultMode)];
  var body = {
    vod: "\n\u3053\u306E\u5EA6\u306F".concat(serviceName, "\u3092\u3054\u5229\u7528\u3044\u305F\u3060\u304D\u3042\u308A\u304C\u3068\u3046\u3054\u3056\u3044\u307E\u3059\u3002\n\u304A\u5BA2\u69D8\u306E\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9\u3067\u4F1A\u54E1\u767B\u9332\u304C\u3054\u3056\u3044\u307E\u3057\u305F\u3002\n\u304A\u5BA2\u69D8\u306F\u65E2\u306B\u3054\u767B\u9332\u6E08\u307F\u3068\u306A\u308A\u307E\u3059\u306E\u3067\u30ED\u30B0\u30A4\u30F3\u753B\u9762\u3088\u308A\u30ED\u30B0\u30A4\u30F3\u3057\u3066\u3054\u5229\u7528\u304F\u3060\u3055\u3044\u3002\n\u30ED\u30B0\u30A4\u30F3\n").concat(config.host.vod).concat(routes.login, "\n\n\u65B0\u305F\u306B\u4F1A\u54E1\u767B\u9332\u3092\u5E0C\u671B\u306E\u5834\u5408\u306F\u3001\u5225\u306E\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9\u306B\u3066\u518D\u5EA6\u4F1A\u54E1\u767B\u9332\u3092\u304A\u9858\u3044\u81F4\u3057\u307E\u3059\u3002\n\n\u5FC3\u5F53\u305F\u308A\u304C\u7121\u3044\u5834\u5408\u3001\u3088\u304F\u3042\u308B\u8CEA\u554F\uFF08https://helpcenter.skyperfectv.co.jp/spoox\uFF09\u3092\u3054\u78BA\u8A8D\u3044\u305F\u3060\u304D\u3001\u5165\u529B\u3044\u305F\u3060\u3044\u305F\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9\u306B\u3064\u3044\u3066\u3054\u78BA\u8A8D\u3092\u304A\u9858\u3044\u3044\u305F\u3057\u307E\u3059\u3002\n\n").concat(footer.vod, "\n    "),
    adult: "\n\u3053\u306E\u5EA6\u306F".concat(serviceName, "\u3092\u3054\u5229\u7528\u3044\u305F\u3060\u304D\u3042\u308A\u304C\u3068\u3046\u3054\u3056\u3044\u307E\u3059\u3002\n\u304A\u5BA2\u69D8\u306E\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9\u3067\u4F1A\u54E1\u767B\u9332\u304C\u3054\u3056\u3044\u307E\u3057\u305F\u3002\n\u304A\u5BA2\u69D8\u306F\u65E2\u306B\u3054\u767B\u9332\u6E08\u307F\u3068\u306A\u308A\u307E\u3059\u306E\u3067\u30ED\u30B0\u30A4\u30F3\u753B\u9762\u3088\u308A\u30ED\u30B0\u30A4\u30F3\u3057\u3066\u3054\u5229\u7528\u304F\u3060\u3055\u3044\u3002\n\u30ED\u30B0\u30A4\u30F3\n").concat(config.host.adult).concat(routes.login, "\n\n\u65B0\u305F\u306B\u4F1A\u54E1\u767B\u9332\u3092\u5E0C\u671B\u306E\u5834\u5408\u306F\u3001\u5225\u306E\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9\u306B\u3066\u518D\u5EA6\u4F1A\u54E1\u767B\u9332\u3092\u304A\u9858\u3044\u81F4\u3057\u307E\u3059\u3002\n\n\u5FC3\u5F53\u305F\u308A\u304C\u7121\u3044\u5834\u5408\u3001\u3088\u304F\u3042\u308B\u8CEA\u554F\uFF08https://helpcenter.skyperfectv.co.jp/spoox\uFF09\u3092\u3054\u78BA\u8A8D\u3044\u305F\u3060\u304D\u3001\u5165\u529B\u3044\u305F\u3060\u3044\u305F\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9\u306B\u3064\u3044\u3066\u3054\u78BA\u8A8D\u3092\u304A\u9858\u3044\u3044\u305F\u3057\u307E\u3059\u3002\n\n").concat(footer.adult, "\n    ")
  };
  return {
    address: address,
    subject: "メールアドレス認証のお知らせ",
    body: body[adultOrVod(adultMode)],
    adultMode: adultMode
  };
}
export function accountEditDuplicateAddress(_ref8, adultMode) {
  var address = _ref8.address;
  var serviceName = config.serviceName[adultOrVod(adultMode)];
  var body = {
    vod: "\n\u3053\u306E\u5EA6\u306F".concat(serviceName, "\u3092\u3054\u5229\u7528\u3044\u305F\u3060\u304D\u3042\u308A\u304C\u3068\u3046\u3054\u3056\u3044\u307E\u3059\u3002\n\u4F1A\u54E1\u60C5\u5831\u5909\u66F4\u3067\u304A\u5BA2\u69D8\u306E\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9\u304C\u5165\u529B\u3055\u308C\u307E\u3057\u305F\u3002\n\u5FC3\u5F53\u305F\u308A\u304C\u306A\u3044\u5834\u5408\u306F\u4E0D\u6B63\u30A2\u30AF\u30BB\u30B9\u306E\u304A\u305D\u308C\u304C\u3042\u308A\u307E\u3059\u306E\u3067\u3001\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9\u3084\u30D1\u30B9\u30EF\u30FC\u30C9\u306E\u5909\u66F4\u3092\u304A\u9858\u3044\u81F4\u3057\u307E\u3059\u3002\n\n").concat(footer.vod, "\n    "),
    adult: "\n\u3053\u306E\u5EA6\u306F".concat(serviceName, "\u3092\u3054\u5229\u7528\u3044\u305F\u3060\u304D\u3042\u308A\u304C\u3068\u3046\u3054\u3056\u3044\u307E\u3059\u3002\n\u4F1A\u54E1\u60C5\u5831\u5909\u66F4\u3067\u304A\u5BA2\u69D8\u306E\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9\u304C\u5165\u529B\u3055\u308C\u307E\u3057\u305F\u3002\n\u5FC3\u5F53\u305F\u308A\u304C\u306A\u3044\u5834\u5408\u306F\u4E0D\u6B63\u30A2\u30AF\u30BB\u30B9\u306E\u304A\u305D\u308C\u304C\u3042\u308A\u307E\u3059\u306E\u3067\u3001\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9\u3084\u30D1\u30B9\u30EF\u30FC\u30C9\u306E\u5909\u66F4\u3092\u304A\u9858\u3044\u81F4\u3057\u307E\u3059\u3002\n\n").concat(footer.adult, "\n    ")
  };
  return {
    address: address,
    subject: "メールアドレス認証のお知らせ",
    body: body[adultOrVod(adultMode)],
    adultMode: adultMode
  };
}
export function signupComplete(_ref9, adultMode) {
  var address = _ref9.address,
    user = _ref9.user;
  var serviceName = config.serviceName[adultOrVod(adultMode)];
  var body = {
    vod: "\n\u3053\u306E\u5EA6\u306F".concat(serviceName, "\u306E\u4F1A\u54E1\u767B\u9332\u3044\u305F\u3060\u304D\u3042\u308A\u304C\u3068\u3046\u3054\u3056\u3044\u307E\u3059\u3002\n\u4F1A\u54E1\u767B\u9332\u304C\u5B8C\u4E86\u3044\u305F\u3057\u307E\u3057\u305F\u3002\n\nSPOOX ID\uFF1A").concat(address, "\n\u4E0A\u8A18ID\u3068\u3054\u767B\u9332\u3044\u305F\u3060\u3044\u305F\u30D1\u30B9\u30EF\u30FC\u30C9\u306B\u3066\u30ED\u30B0\u30A4\u30F3\u3057\u3066\u304A\u697D\u3057\u307F\u304F\u3060\u3055\u3044\u3002\n\u30FB\u30ED\u30B0\u30A4\u30F3\u306F\u3053\u3061\u3089\n").concat(config.host.vod).concat(routes.login, "\n\n\n").concat(footer.vod, "\n    "),
    adult: "\n\u3053\u306E\u5EA6\u306F".concat(serviceName, "\u306E\u4F1A\u54E1\u767B\u9332\u3044\u305F\u3060\u304D\u3042\u308A\u304C\u3068\u3046\u3054\u3056\u3044\u307E\u3059\u3002\n\u4F1A\u54E1\u767B\u9332\u304C\u5B8C\u4E86\u3044\u305F\u3057\u307E\u3057\u305F\u3002\n\nSPOOX ID\uFF1A").concat(address, "\n\u4E0A\u8A18ID\u3068\u3054\u767B\u9332\u3044\u305F\u3060\u3044\u305F\u30D1\u30B9\u30EF\u30FC\u30C9\u306B\u3066\u30ED\u30B0\u30A4\u30F3\u3057\u3066\u304A\u697D\u3057\u307F\u304F\u3060\u3055\u3044\u3002\n\u30FB\u30ED\u30B0\u30A4\u30F3\u306F\u3053\u3061\u3089\n").concat(config.host.adult).concat(routes.login, "\n\n").concat(footer.adult, "\n    ")
  };
  return {
    address: address,
    subject: "会員登録完了のお知らせ",
    body: body[adultOrVod(adultMode)],
    adultMode: adultMode
  };
}
export function myskpSignupComplete(_ref10, adultMode) {
  var address = _ref10.address,
    user = _ref10.user;
  var serviceName = config.serviceName[adultOrVod(adultMode)];
  var body = {
    vod: "\n\u3053\u306E\u5EA6\u306F".concat(serviceName, "\u306E\u4F1A\u54E1\u767B\u9332\u3044\u305F\u3060\u304D\u3042\u308A\u304C\u3068\u3046\u3054\u3056\u3044\u307E\u3059\u3002\n\u4F1A\u54E1\u767B\u9332\u304C\u5B8C\u4E86\u3044\u305F\u3057\u307E\u3057\u305F\u3002\n\n\u3054\u5165\u529B\u3044\u305F\u3060\u3044\u305FMy\u30B9\u30AB\u30D1\u30FC\uFF01ID\u307E\u305F\u306F\u4EE5\u4E0BSPOOX ID\u306B\u3066\u30ED\u30B0\u30A4\u30F3\u3057\u3066\u304A\u697D\u3057\u307F\u304F\u3060\u3055\u3044\u3002\nSPOOX ID\uFF1A").concat(address, "\n\u4E0A\u8A18ID\u3068\u3054\u767B\u9332\u3044\u305F\u3060\u3044\u305F\u30D1\u30B9\u30EF\u30FC\u30C9\u306B\u3066\u30ED\u30B0\u30A4\u30F3\u3057\u3066\u304A\u697D\u3057\u307F\u304F\u3060\u3055\u3044\u3002\n\u30FB\u30ED\u30B0\u30A4\u30F3\u306F\u3053\u3061\u3089\n").concat(config.host.vod).concat(routes.login, "\n\n\n").concat(footer.vod, "\n    "),
    adult: "\n\u3053\u306E\u5EA6\u306F".concat(serviceName, "\u306E\u4F1A\u54E1\u767B\u9332\u3044\u305F\u3060\u304D\u3042\u308A\u304C\u3068\u3046\u3054\u3056\u3044\u307E\u3059\u3002\n\u4F1A\u54E1\u767B\u9332\u304C\u5B8C\u4E86\u3044\u305F\u3057\u307E\u3057\u305F\u3002\n\n\u3054\u5165\u529B\u3044\u305F\u3060\u3044\u305FMy\u30B9\u30AB\u30D1\u30FC\uFF01ID\u307E\u305F\u306F\u4EE5\u4E0BSPOOX ID\u306B\u3066\u30ED\u30B0\u30A4\u30F3\u3057\u3066\u304A\u697D\u3057\u307F\u304F\u3060\u3055\u3044\u3002\nSPOOX ID\uFF1A").concat(address, "\n\u4E0A\u8A18ID\u3068\u3054\u767B\u9332\u3044\u305F\u3060\u3044\u305F\u30D1\u30B9\u30EF\u30FC\u30C9\u306B\u3066\u30ED\u30B0\u30A4\u30F3\u3057\u3066\u304A\u697D\u3057\u307F\u304F\u3060\u3055\u3044\u3002\n\u30FB\u30ED\u30B0\u30A4\u30F3\u306F\u3053\u3061\u3089\n").concat(config.host.adult).concat(routes.login, "\n\n").concat(footer.adult, "\n    ")
  };
  return {
    address: address,
    subject: "会員登録完了のお知らせ",
    body: body[adultOrVod(adultMode)],
    adultMode: adultMode
  };
}
export function userUpdate(_ref11, adultMode) {
  var address = _ref11.address,
    user = _ref11.user;
  var serviceName = config.serviceName[adultOrVod(adultMode)];
  var body = {
    vod: "\n\u3053\u306E\u5EA6\u306F".concat(serviceName, "\u3092\u3054\u5229\u7528\u3044\u305F\u3060\u304D\u3042\u308A\u304C\u3068\u3046\u3054\u3056\u3044\u307E\u3059\u3002\n\u304A\u5BA2\u69D8\u60C5\u5831\u306E\u5909\u66F4\u304C\u5B8C\u4E86\u3044\u305F\u3057\u307E\u3057\u305F\u3002\n\u5909\u66F4\u5F8C\u306E\u60C5\u5831\u306F\u30A2\u30AB\u30A6\u30F3\u30C8\u30DA\u30FC\u30B8\u3088\u308A\u3054\u78BA\u8A8D\u3044\u305F\u3060\u3051\u307E\u3059\u3002\n\n\n").concat(footer.vod, "\n    "),
    adult: "\n\u3053\u306E\u5EA6\u306F".concat(serviceName, "\u3092\u3054\u5229\u7528\u3044\u305F\u3060\u304D\u3042\u308A\u304C\u3068\u3046\u3054\u3056\u3044\u307E\u3059\u3002\n\u304A\u5BA2\u69D8\u60C5\u5831\u306E\u5909\u66F4\u304C\u5B8C\u4E86\u3044\u305F\u3057\u307E\u3057\u305F\u3002\n\u5909\u66F4\u5F8C\u306E\u60C5\u5831\u306F\u30A2\u30AB\u30A6\u30F3\u30C8\u30DA\u30FC\u30B8\u3088\u308A\u3054\u78BA\u8A8D\u3044\u305F\u3060\u3051\u307E\u3059\u3002\n\n\n").concat(footer.adult, "\n    ")
  };
  return {
    address: address,
    subject: "ご登録情報変更のお知らせ",
    body: body[adultOrVod(adultMode)],
    adultMode: adultMode
  };
}
export function withdrawalComplete(_ref12, adultMode) {
  var address = _ref12.address,
    user = _ref12.user;
  var serviceName = config.serviceName[adultOrVod(adultMode)];
  var body = {
    vod: "\n".concat(serviceName, "\u306E\u3054\u5229\u7528\u3001\u8AA0\u306B\u3042\u308A\u304C\u3068\u3046\u3054\u3056\u3044\u307E\u3057\u305F\u3002\n\u9000\u4F1A\u304C\u5B8C\u4E86\u3044\u305F\u3057\u307E\u3057\u305F\u3002\n\u307E\u305F\u306E\u3054\u5229\u7528\u3001\u5FC3\u3088\u308A\u304A\u5F85\u3061\u3057\u3066\u304A\u308A\u307E\u3059\u3002\n\n").concat(footer.vod, "\n    "),
    adult: "\n".concat(serviceName, "\u306E\u3054\u5229\u7528\u3001\u8AA0\u306B\u3042\u308A\u304C\u3068\u3046\u3054\u3056\u3044\u307E\u3057\u305F\u3002\n\u9000\u4F1A\u304C\u5B8C\u4E86\u3044\u305F\u3057\u307E\u3057\u305F\u3002\n\u307E\u305F\u306E\u3054\u5229\u7528\u3001\u5FC3\u3088\u308A\u304A\u5F85\u3061\u3057\u3066\u304A\u308A\u307E\u3059\u3002\n\n").concat(footer.adult, "\n    ")
  };
  return {
    address: address,
    subject: "退会のお知らせ",
    body: body[adultOrVod(adultMode)],
    adultMode: adultMode
  };
}
export function myskpWithdrawalComplete(_ref13, adultMode) {
  var address = _ref13.address,
    user = _ref13.user;
  var serviceName = config.serviceName[adultOrVod(adultMode)];
  var body = {
    vod: "\n".concat(serviceName, "\u306E\u3054\u5229\u7528\u3001\u8AA0\u306B\u3042\u308A\u304C\u3068\u3046\u3054\u3056\u3044\u307E\u3057\u305F\u3002\n\u9000\u4F1A\u304C\u5B8C\u4E86\u3044\u305F\u3057\u307E\u3057\u305F\u3002\nMy\u30B9\u30AB\u30D1\u30FC\uFF01ID\u306E\u524A\u9664\u3092\u3054\u5E0C\u671B\u306E\u5834\u5408\u306FMy\u30B9\u30AB\u30D1\u30FC\uFF01\u30B5\u30A4\u30C8\u3088\u308A\u304A\u624B\u7D9A\u304D\u3092\u304A\u9858\u3044\u3044\u305F\u3057\u307E\u3059\u3002\n\n\u307E\u305F\u306E\u3054\u5229\u7528\u3001\u5FC3\u3088\u308A\u304A\u5F85\u3061\u3057\u3066\u304A\u308A\u307E\u3059\u3002\n\n").concat(footer.vod, "\n    "),
    adult: "\n".concat(serviceName, "\u306E\u3054\u5229\u7528\u3001\u8AA0\u306B\u3042\u308A\u304C\u3068\u3046\u3054\u3056\u3044\u307E\u3057\u305F\u3002\n\u9000\u4F1A\u304C\u5B8C\u4E86\u3044\u305F\u3057\u307E\u3057\u305F\u3002\nMy\u30B9\u30AB\u30D1\u30FC\uFF01ID\u306E\u524A\u9664\u3092\u3054\u5E0C\u671B\u306E\u5834\u5408\u306FMy\u30B9\u30AB\u30D1\u30FC\uFF01\u30B5\u30A4\u30C8\u3088\u308A\u304A\u624B\u7D9A\u304D\u3092\u304A\u9858\u3044\u3044\u305F\u3057\u307E\u3059\u3002\n\n\u307E\u305F\u306E\u3054\u5229\u7528\u3001\u5FC3\u3088\u308A\u304A\u5F85\u3061\u3057\u3066\u304A\u308A\u307E\u3059\u3002\n\n").concat(footer.adult, "\n    ")
  };
  return {
    address: address,
    subject: "退会のお知らせ",
    body: body[adultOrVod(adultMode)],
    adultMode: adultMode
  };
}
export function addPaymentInstrument(_ref14, adultMode) {
  var address = _ref14.address,
    paymentInstrument = _ref14.paymentInstrument;
  var paymentMethod = paymentInstrument.payment_method;
  var adultOrVod = adultMode ? "adult" : "vod";
  var body = function () {
    if (paymentMethod.payment_method_id === PAYMENT_METHOD_ID.CREDIT_CARD) {
      var _paymentInstrument$va;
      var cardNumber = "****-****-****-".concat(paymentInstrument === null || paymentInstrument === void 0 || (_paymentInstrument$va = paymentInstrument.values) === null || _paymentInstrument$va === void 0 ? void 0 : _paymentInstrument$va.card_number);
      return "\n\u304A\u652F\u6255\u3044\u60C5\u5831\u306E\u767B\u9332\u304C\u5B8C\u4E86\u3044\u305F\u3057\u307E\u3057\u305F\u3002\n\n\u25A0\u304A\u652F\u6255\u3044\u60C5\u5831\n\u304A\u652F\u6255\u3044\u60C5\u5831: ".concat(paymentMethod.name, "\n\u30AF\u30EC\u30B8\u30C3\u30C8\u30AB\u30FC\u30C9\u756A\u53F7: ").concat(cardNumber, "\n\n").concat(footer[adultOrVod], "\n");
    } else {
      return "\n\u304A\u652F\u6255\u3044\u60C5\u5831\u306E\u767B\u9332\u304C\u5B8C\u4E86\u3044\u305F\u3057\u307E\u3057\u305F\u3002\n\n\u25A0\u304A\u652F\u6255\u3044\u60C5\u5831\n\u304A\u652F\u6255\u3044\u60C5\u5831: ".concat(paymentMethod.name, "\n\n").concat(footer[adultOrVod], "\n");
    }
  }();
  return {
    address: address,
    subject: "お支払い情報の登録が完了しました",
    body: body,
    adultMode: adultMode
  };
}
export function changePaymentInstrument(_ref15, adultMode) {
  var address = _ref15.address,
    course = _ref15.course,
    paymentInstrument = _ref15.paymentInstrument;
  var paymentMethod = paymentInstrument.payment_method;
  var adultOrVod = adultMode ? "adult" : "vod";
  var body = function () {
    if (paymentMethod.payment_method_id === PAYMENT_METHOD_ID.CREDIT_CARD) {
      var _paymentInstrument$va2;
      var cardNumber = "****-****-****-".concat(paymentInstrument === null || paymentInstrument === void 0 || (_paymentInstrument$va2 = paymentInstrument.values) === null || _paymentInstrument$va2 === void 0 ? void 0 : _paymentInstrument$va2.card_number);
      return "\n".concat(course.name, "\u306E\u304A\u652F\u6255\u3044\u60C5\u5831\u3092\u5909\u66F4\u3044\u305F\u3057\u307E\u3057\u305F\u3002\n\n\u25A0\u304A\u652F\u6255\u3044\u60C5\u5831\n\u304A\u652F\u6255\u3044\u60C5\u5831: ").concat(paymentMethod.name, "\n\u30AF\u30EC\u30B8\u30C3\u30C8\u30AB\u30FC\u30C9\u756A\u53F7: ").concat(cardNumber, "\n\n").concat(footer[adultOrVod], "\n");
    } else {
      return "\n".concat(course.name, "\u306E\u304A\u652F\u6255\u3044\u60C5\u5831\u3092\u5909\u66F4\u3044\u305F\u3057\u307E\u3057\u305F\u3002\n\n\u25A0\u304A\u652F\u6255\u3044\u60C5\u5831\n\u304A\u652F\u6255\u3044\u60C5\u5831: ").concat(paymentMethod.name, "\n\n").concat(footer[adultOrVod], "\n");
    }
  }();
  return {
    address: address,
    subject: "お支払い情報の変更が完了しました",
    body: body,
    adultMode: adultMode
  };
}