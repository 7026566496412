// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import { BrowserTracing } from "@sentry/tracing";
var SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
var env = process.env.APP_ENV || "production" || "development";
Sentry.init({
  dsn: SENTRY_DSN || "https://d08c537025954153809796a23fd4138a@o119276.ingest.sentry.io/5973267",
  // Adjust this value in production, or use tracesSampler for greater control
  integrations: [new BrowserTracing({
    tracingOrigins: ["spoox.skyperfectv.co.jp", "spoox-ex.skyperfectv.co.jp"]
  })],
  tracesSampleRate: 0.05,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  environment: env,
  shouldSendCallback: function shouldSendCallback() {
    return ["production", "staging"].includes(env);
  }
});