import { useState, useEffect, useCallback } from "react";
import throttle from "lodash.throttle";
export function useWindowSize() {
  var _useState = useState({
      width: undefined,
      height: undefined
    }),
    windowSize = _useState[0],
    setWindowSize = _useState[1];
  function handleResize() {
    setWindowSize({
      width: document.body.clientWidth,
      height: document.body.clientHeight
    });
  }
  var throttleHandler = useCallback(throttle(handleResize, 300), []);
  useEffect(function () {
    if (true) {
      window.addEventListener("resize", throttleHandler);
      handleResize();
      return function () {
        window.removeEventListener("resize", throttleHandler);
      };
    }
  }, []);
  return windowSize;
}