import { useState, useCallback, useEffect } from "react";
import throttle from "lodash.throttle";
export function useMatchMedia(queries, defaultValue) {
  var _useState = useState(null),
    matchedValue = _useState[0],
    setMatchedValue = _useState[1];
  function handleResize() {
    var _matchedValue = function () {
      var filterd = queries.filter(function (obj) {
        return !!window.matchMedia(obj.query).matches;
      });
      if (filterd.length > 0) {
        return filterd[0].value;
      } else {
        return defaultValue;
      }
    }();
    setMatchedValue(_matchedValue);
  }
  var throttleHandler = useCallback(throttle(handleResize, 300), []);
  useEffect(function () {
    if (true) {
      window.addEventListener("resize", throttleHandler);
      handleResize();
    }
    return function () {
      window.removeEventListener("resize", throttleHandler);
    };
  }, []);
  return matchedValue;
}