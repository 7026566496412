export var channelsSort = [{
  text: "おすすめ順",
  value: "pr",
  sort: [{
    push_rank: "desc"
  }, {
    created_at: "desc"
  }, {
    id: "desc"
  }]
}, {
  text: "新着作品順",
  value: "ps",
  sort: [{
    publish_start_at: "desc"
  }, {
    id: "desc"
  }]
}];
export var titlesSort = [{
  text: "おすすめ順",
  value: "pr",
  sort: [{
    push_rank: "desc"
  }, {
    created_at: "desc"
  }, {
    id: "desc"
  }]
}, {
  text: "新着作品順",
  value: "ps",
  sort: [{
    publish_start_at: "desc"
  }, {
    name: "asc"
  }, {
    id: "desc"
  }]
}];
export var searchSort = [{
  text: "関連度順",
  value: "rel",
  sort: [{
    _score: "desc"
  }, {
    name: "asc"
  }, {
    id: "desc"
  }]
}, {
  text: "おすすめ順",
  value: "pr",
  sort: [{
    push_rank: "desc"
  }, {
    created_at: "desc"
  }, {
    id: "desc"
  }]
}, {
  text: "新着作品順",
  value: "ps",
  sort: [{
    publish_start_at: "desc"
  }, {
    id: "desc"
  }]
}, {
  text: "もうすぐ終了順",
  value: "de",
  sort: [{
    delivery_end_at: "asc"
  }, {
    id: "desc"
  }]
}, {
  text: "50音順（あ→わ）",
  value: "az",
  sort: [{
    name: "asc"
  }, {
    id: "desc"
  }]
}, {
  text: "配信開始日順",
  value: "ds",
  sort: [{
    delivery_start_at: "asc"
  }, {
    id: "desc"
  }]
}];
export var searchTvShowSort = [{
  text: "関連度順",
  value: "rel",
  sort: [{
    _score: "desc"
  }, {
    name: "asc"
  }, {
    id: "desc"
  }]
}, {
  text: "おすすめ順",
  value: "pr",
  sort: [{
    push_rank: "desc"
  }, {
    created_at: "desc"
  }, {
    id: "desc"
  }]
}, {
  text: "新着作品順",
  value: "ps",
  sort: [{
    publish_start_at: "desc"
  }, {
    id: "desc"
  }]
}, {
  text: "50音順（あ→わ）",
  value: "az",
  sort: [{
    name: "asc"
  }, {
    id: "desc"
  }]
}];
export var myListWatchHistorySort = [{
  text: "視聴日が新しい順",
  value: "desc",
  sort: "last_viewing_at"
}, {
  text: "視聴日が古い順",
  value: "asc",
  sort: "last_viewing_at"
}];
export var myListFavoritesSort = {
  value: "desc",
  sort: "created_at"
};
export var genreSort = [{
  text: "おすすめ順",
  value: "pr",
  sort: [{
    push_rank: "desc"
  }, {
    id: "desc"
  }]
}, {
  text: "公開日時",
  value: "ps",
  sort: [{
    publish_start_at: "desc"
  }, {
    id: "desc"
  }]
}, {
  text: "名前（昇順）",
  value: "az",
  sort: [{
    name: "asc"
  }, {
    id: "desc"
  }]
}, {
  text: "名前（降順）",
  value: "za",
  sort: [{
    name: "desc"
  }, {
    id: "desc"
  }]
}];