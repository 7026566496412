import getConfig from "next/config";
import merge from "deepmerge";
var _getConfig = getConfig(),
  serverRuntimeConfig = _getConfig.serverRuntimeConfig,
  publicRuntimeConfig = _getConfig.publicRuntimeConfig;
var config = merge(serverRuntimeConfig, publicRuntimeConfig);
export default config;
export function getStoreId(adultMode) {
  var adultOrVod = adultMode ? "adult" : "vod";
  return config.storeId[adultOrVod];
}