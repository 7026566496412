import { useMatchMedia } from "./useMatchMedia";
export function useColumsInRow() {
  return useMatchMedia([{
    query: "screen and (min-width: 1400px)",
    value: 6
  }, {
    query: "screen and (min-width: 1100px) and (max-width: 1399px)",
    value: 6
  }, {
    query: "screen and (min-width: 800px) and (max-width: 1099px)",
    value: 4
  }, {
    query: "screen and (min-width: 500px) and (max-width: 799px)",
    value: 3
  }, {
    query: "screen and (max-width: 499px)",
    value: 2
  }], 1);
}