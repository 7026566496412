import _taggedTemplateLiteral from "/build/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject;
import { gql } from "@apollo/client";
import { dateFormat } from "./helper";
export var typeDef = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  type VoucherCode {\n    id: ID\n    code: String\n    voucherId: Int\n    validityStartAt: String\n    validityEndAt: String\n    expire_at: String\n    isAvailable: Boolean\n    voucher: Voucher\n  }\n\n  type Voucher {\n    id: ID\n    name: String\n    description: String\n    discountPrice: Int\n    limitPrice: Int\n    trialExtendTerm: Int\n    trialExtendTermUnit: String\n    trialExtendEndOf: String\n    validityStartAt: String\n    validityEndAt: String\n  }\n"])));
export var resolvers = {
  VoucherCode: {
    id: function id(v) {
      return v.voucher_code_id;
    },
    voucherId: function voucherId(v) {
      return v.voucher_id;
    },
    isAvailable: function isAvailable(v) {
      return v.is_available;
    },
    validityStartAt: function validityStartAt(v) {
      return dateFormat(v.validity_start_at);
    },
    validityEndAt: function validityEndAt(v) {
      return dateFormat(v.validity_end_at);
    }
  },
  Voucher: {
    id: function id(v) {
      return v.voucher_id;
    },
    discountPrice: function discountPrice(v) {
      return v.discount_price;
    },
    limitPrice: function limitPrice(v) {
      return v.limit_price;
    },
    trialExtendTerm: function trialExtendTerm(v) {
      return v.trial_extend_term;
    },
    trialExtendTermUnit: function trialExtendTermUnit(v) {
      var _v$trial_extend_term_;
      return v === null || v === void 0 || (_v$trial_extend_term_ = v.trial_extend_term_unit) === null || _v$trial_extend_term_ === void 0 ? void 0 : _v$trial_extend_term_.split("_").slice(-1)[0];
    },
    trialExtendEndOf: function trialExtendEndOf(v) {
      var _v$trial_extend_end_o;
      return v === null || v === void 0 || (_v$trial_extend_end_o = v.trial_extend_end_of) === null || _v$trial_extend_end_o === void 0 ? void 0 : _v$trial_extend_end_o.split("_").slice(-1)[0];
    },
    validityStartAt: function validityStartAt(v) {
      return dateFormat(v.validity_start_at);
    },
    validityEndAt: function validityEndAt(v) {
      return dateFormat(v.validity_end_at);
    }
  }
};