import crypto from "crypto";
import * as Redis from "ioredis";
var connections = {};
export default function connectRedis(config) {
  var cluster = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var key = crypto.createHash("SHA256").update(JSON.stringify(config)).digest("hex");
  if (connections[key]) {
    return connections[key];
  } else {
    if (cluster) {
      var redis = new Redis.Cluster([config]);
      connections[key] = redis;
      return redis;
    } else {
      var _redis = new Redis(config);
      connections[key] = _redis;
      return _redis;
    }
  }
}