import { useRef, useState, useCallback, useEffect } from "react";
import { useRouter } from "next/router";
import isOuterArea from "@/lib/isOuterArea";
export function useDropdown(_ref) {
  var mouseOver = _ref.mouseOver;
  var router = useRouter();
  var buttonRef = useRef();
  var contentRef = useRef();
  var _useState = useState(false),
    show = _useState[0],
    setShow = _useState[1];
  var _useState2 = useState(false),
    hovered = _useState2[0],
    setHovered = _useState2[1];
  var handleClick = useCallback(function () {
    return setShow(!show);
  }, [show]);
  var handleMouseOver = useCallback(function () {
    return setHovered(true);
  }, []);
  var handleMouseLeave = useCallback(function () {
    return setHovered(false);
  }, []);
  var listener = useCallback(function (e) {
    if (buttonRef.current && contentRef.current) {
      if (isOuterArea(buttonRef.current, e) && isOuterArea(contentRef.current, e)) {
        setShow(false);
      }
    }
  }, []);
  useEffect(function () {
    var routeChangeHandler = function routeChangeHandler() {
      return setShow(false);
    };
    if (typeof document !== "undefined") {
      router.events.on("routeChangeStart", routeChangeHandler);
      router.events.on("hashChangeStart", routeChangeHandler);
      document.addEventListener("click", listener, false);
    }
    return function () {
      if (typeof document !== "undefined") {
        router.events.off("routeChangeStart", routeChangeHandler);
        router.events.off("hashChangeStart", routeChangeHandler);
        document.removeEventListener("click", listener, false);
      }
    };
  }, []);
  useEffect(function () {
    var timeoutId;
    if (mouseOver) {
      timeoutId = setTimeout(function () {
        setShow(hovered);
      }, 300);
    }
    return function () {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [mouseOver, hovered]);
  return {
    buttonRef: buttonRef,
    contentRef: contentRef,
    show: show,
    setShow: setShow,
    hovered: hovered,
    listeners: {
      handleClick: handleClick,
      handleMouseOver: handleMouseOver,
      handleMouseLeave: handleMouseLeave
    }
  };
}