import { mergeResolvers, mergeTypeDefs } from "@graphql-tools/merge";
import { makeExecutableSchema } from "@graphql-tools/schema";
import * as adultMode from "./adultMode";
import * as advert from "./advert";
import * as attributes from "./attributes";
import * as canvas from "./canvas";
import * as common from "./common";
import * as course from "./course";
import * as deviceInfo from "./deviceInfo";
import * as entitlement from "./entitlement";
import * as htmlSnippet from "./htmlSnippet";
import * as information from "./information";
import * as link from "./link";
import * as meta from "./meta";
import * as myskp from "./myskp";
import * as order from "./order";
import * as palette from "./palette";
import * as paymentInstruments from "./paymentInstruments";
import * as playback from "./playback";
import * as product from "./product";
import * as purchase from "./purchase";
import * as paymentMethods from "./paymentMethods";
import * as right from "./right";
import * as subscribe from "./subscribe";
import * as terms from "./terms";
import * as user from "./user";
import * as watch from "./watch";
import * as webPage from "./webPage";
import * as ranking from "./ranking";
import * as recommend from "./recommend";
import * as voucherCode from "./voucherCode";
import * as campaign from "./campaign";
import * as rtoaster from "./rtoaster";
import * as searchConfig from "./searchConfig";
import * as pinManager from "./pinManager";
var schema = makeExecutableSchema({
  typeDefs: mergeTypeDefs([common.typeDef, user.typeDef, adultMode.typeDef, deviceInfo.typeDef, meta.typeDef, advert.typeDef, htmlSnippet.typeDef, webPage.typeDef, palette.typeDef, paymentMethods.typeDef, paymentInstruments.typeDef, purchase.typeDef, subscribe.typeDef, watch.typeDef, playback.typeDef, right.typeDef, course.typeDef, product.typeDef, entitlement.typeDef, order.typeDef, terms.typeDef, canvas.typeDef, link.typeDef, information.typeDef, attributes.typeDef, myskp.typeDef, ranking.typeDef, recommend.typeDef, voucherCode.typeDef, campaign.typeDef, rtoaster.typeDef, searchConfig.typeDef, pinManager.typeDef]),
  resolvers: mergeResolvers([common.resolvers, user.resolvers, adultMode.resolvers, deviceInfo.resolvers, meta.resolvers, advert.resolvers, htmlSnippet.resolvers, webPage.resolvers, palette.resolvers, paymentMethods.resolvers, paymentInstruments.resolvers, purchase.resolvers, subscribe.resolvers, watch.resolvers, playback.resolvers, right.resolvers, course.resolvers, product.resolvers, entitlement.resolvers, order.resolvers, terms.resolvers, canvas.resolvers, link.resolvers, information.resolvers, attributes.resolvers, myskp.resolvers, ranking.resolvers, recommend.resolvers, voucherCode.resolvers, campaign.resolvers, rtoaster.resolvers, searchConfig.resolvers, pinManager.resolvers])
});
export default schema;