import { useState, useCallback } from "react";
export function useUppercaseCaution() {
  var _useState = useState(),
    uppercaseCautionMessage = _useState[0],
    setUppercaseCautionMessage = _useState[1];
  var handleChange = useCallback(function (e) {
    var hasUppercase = /[A-Z]/.test(e.target.value);
    if (hasUppercase) {
      setUppercaseCautionMessage("大文字が入力されていますが正しいかご確認の上、次のステップにすすんでください");
    } else {
      setUppercaseCautionMessage(null);
    }
  }, []);
  return [handleChange, uppercaseCautionMessage];
}