import { useEffect, useState } from "react";
export function useOnLineStatus() {
  var _useState = useState(true),
    onLine = _useState[0],
    setOnLine = _useState[1];
  useEffect(function () {
    var timeoutId;
    setOnLine(window.navigator.onLine);
    var listener = function listener() {
      timeoutId = setTimeout(function () {
        setOnLine(window.navigator.onLine);
      }, window.navigator.onLine ? 1 : 100);
    };
    window.addEventListener("online", listener);
    window.addEventListener("offline", listener);
    return function () {
      clearTimeout(timeoutId);
      window.removeEventListener("online", listener);
      window.removeEventListener("offline", listener);
    };
  }, []);
  return onLine;
}