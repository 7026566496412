import { useEffect, useState } from "react";
export function usePaymentInstrumentToken(token) {
  var _useState = useState(null),
    payload = _useState[0],
    setPayload = _useState[1];
  useEffect(function () {
    if (token) {
      fetch("/api/payment_instrument_token", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          token: token
        })
      }).then(function (res) {
        return res.json();
      }).then(function (json) {
        return setPayload(json);
      });
    }
  }, [token]);
  return payload;
}