import dayjs from "@/lib/day";

/**
 * calcCampaignEndAt.
 *
 * キャンペーンに設定された適用回数をもとにキャンペーン終了日時を計算する
 *
 * @param {Object} campaign
 * @param {string} initialTransactionAt
 */
export default function calcCampaignEndAt(campaign, initialTransactionAt) {
  var validityEndAt = campaign === null || campaign === void 0 ? void 0 : campaign.validityEndAt;
  var applyCount = (campaign === null || campaign === void 0 ? void 0 : campaign.discountApplyCount) || 0;
  if (applyCount > 0) {
    var endAt = dayjs(new Date(initialTransactionAt)).add(applyCount, "month").subtract(1, "day");
    if (endAt.isAfter(validityEndAt)) {
      return validityEndAt;
    } else {
      return endAt.format("YYYY/MM/DD");
    }
  } else {
    return validityEndAt;
  }
}