import _defineProperty from "/build/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _toConsumableArray from "/build/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import _slicedToArray from "/build/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import { useApolloClient } from "@apollo/client";
import { useRtTrackMutation, useRtRecommendQuery } from "@/hooks";
import { RTOASTER_RECOMMEND_QUERY } from "@/apollo/queries/rtoaster";

/**
 * useRtoasterTrack.
 *
 * rtoasterのtrackを送信する
 *
 * @param {boolean} adultMode
 * @param {string} referer
 */
export function useRtoasterTrack(adultMode, referer) {
  var router = useRouter();
  var _useRtTrackMutation = useRtTrackMutation(),
    _useRtTrackMutation2 = _slicedToArray(_useRtTrackMutation, 1),
    rtoasterTrack = _useRtTrackMutation2[0];
  var referrer = useRef(referer);
  useEffect(function () {
    var handler = function handler() {
      var queryString = _toConsumableArray(new URLSearchParams(window.location.search).entries()).reduce(function (obj, e) {
        return _objectSpread(_objectSpread({}, obj), {}, _defineProperty({}, e[0], e[1]));
      }, {});
      rtoasterTrack({
        variables: {
          location: "".concat(window.location.origin).concat(window.location.pathname),
          referrer: referrer.current,
          sid: queryString === null || queryString === void 0 ? void 0 : queryString.rtsid
        }
      });
      referrer.current = window.location.href;
    };
    if (!adultMode) {
      router.events.on("routeChangeComplete", handler);
      handler();
    }
    return function () {
      router.events.off("routeChangeComplete", handler);
    };
  }, [adultMode]);
}

/**
 * useRtoasterRecommendWithCache.
 *
 * Rtoasterのレコメンドを取得して、elemendIdが1件ずつ指定された場合のROOT_QUERYのキャッシュを作る
 *
 * @param {string[]} elementIds
 */
export function useRtoasterRecommendWithCache(elementIds) {
  var client = useApolloClient();
  var _useState = useState(false),
    dataCached = _useState[0],
    setDataCached = _useState[1];
  var result = useRtRecommendQuery({
    variables: {
      location: "".concat(window.location.origin).concat(window.location.pathname),
      elementIds: elementIds
    },
    onCompleted: function onCompleted(data) {
      if (data !== null && data !== void 0 && data.recommend) {
        var _iterator = _createForOfIteratorHelper(elementIds),
          _step;
        try {
          var _loop = function _loop() {
            var elementId = _step.value;
            var cacheData = data.recommend.find(function (d) {
              return d.elementId === elementId;
            });
            if (cacheData) {
              client.writeQuery({
                query: RTOASTER_RECOMMEND_QUERY,
                data: {
                  recommend: [cacheData]
                },
                variables: {
                  location: "".concat(window.location.origin).concat(window.location.pathname),
                  elementIds: [elementId]
                }
              });
            }
          };
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            _loop();
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
      }
      setDataCached(true);
    }
  });
  return _objectSpread(_objectSpread({}, result), {}, {
    loading: !dataCached
  });
}