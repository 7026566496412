import _taggedTemplateLiteral from "/build/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject;
import { gql } from "@apollo/client";
import { values } from "./helper";
import dayjs from "@/lib/day";
export var typeDef = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  type Link {\n    id: ID!\n    title: String\n    url: String\n    description: String\n    targetBlank: Boolean\n    date: String\n    startAt: String\n    endAt: String\n    values: JSONObject\n  }\n"])));
export var resolvers = {
  Link: {
    id: function id(link) {
      return (link === null || link === void 0 ? void 0 : link.id) || (link === null || link === void 0 ? void 0 : link.link_id);
    },
    targetBlank: function targetBlank(link) {
      return values(link, "target_blank");
    },
    date: function date(link) {
      var date = dayjs(link.validity_start_at);
      if (date.isValid()) {
        return date.format("YYYY/M/D");
      } else {
        return null;
      }
    },
    startAt: function startAt(link) {
      return link.validity_start_at;
    },
    endAt: function endAt(link) {
      return link.validity_end_at;
    }
  }
};