import { useEffect } from "react";
export function useFillWidth() {
  useEffect(function () {
    var setFillWidth = function setFillWidth() {
      var vw = window.document.body.clientWidth * 0.01;
      document.documentElement.style.setProperty("--vw", "".concat(vw, "px"));
    };
    var vw = window.innerWidth;
    var handleResize = function handleResize() {
      if (vw === window.innerWidth) return;
      vw = window.innerWidth;
      setFillWidth();
    };
    window.addEventListener("resize", handleResize);
    var timeoutId = setTimeout(function () {
      return setFillWidth;
    }, 10);
    return function () {
      clearTimeout(timeoutId);
      window.removeEventListener("resize", handleResize);
    };
  }, []);
}