import { useMemo } from "react";
import dayjs from "@/lib/day";
import calcCampaignEndAt from "@/lib/calcCampaignEndAt";

/**
 * useExtendedTrial.
 *
 * @param {boolean} isTrial
 * @param {string} trialEndAt
 * @param {string} initialTransactionAt
 * @param {Object} voucherCode
 */
export function useExtendedTrial(isTrial, trialEndAt, initialTransactionAt, voucherCode) {
  var extendedTrialEndAt = useMemo(function () {
    if (!isTrial) return null;
    if (voucherCode) {
      var voucher = voucherCode.voucher;
      var endAt = dayjs(new Date(trialEndAt));
      var extendEndAt = endAt.add(voucher.trialExtendTerm, voucher.trialExtendTermUnit);
      if (voucher.trialExtendEndOf !== "now") {
        return extendEndAt.endOf(voucher.trialExtendEndOf).format("YYYY/MM/DD");
      }
      return extendEndAt.format("YYYY/MM/DD");
    }
    return null;
  }, [isTrial, trialEndAt, voucherCode]);
  var calcInitialTransactionAt = useMemo(function () {
    if (extendedTrialEndAt) {
      return dayjs(new Date(extendedTrialEndAt)).add(1, "day").format("YYYY/MM/DD");
    } else {
      return initialTransactionAt;
    }
  }, [extendedTrialEndAt, initialTransactionAt]);
  return [extendedTrialEndAt, calcInitialTransactionAt];
}

/**
 * useCampaignApplyingRange.
 *
 * キャンペーン適用範囲を計算する
 *
 * @param {Object} campaign
 * @param {string} initialTransactionAt
 * @param {boolean} isTrial
 * @param {string} trialEndAt
 */
export function useCampaignApplyingRange(campaign, initialTransactionAt, isTrial, trialEndAt) {
  // トライアルがある場合は、キャンペーンの開始日時を再計算する
  var start = useMemo(function () {
    if (isTrial) {
      return dayjs(new Date(trialEndAt)).add(1, "day").format("YYYY/MM/DD");
    } else {
      return initialTransactionAt;
    }
  }, [campaign, isTrial, trialEndAt]);

  // キャンペーン終了日時は、初回決済日からnヶ月後
  var end = useMemo(function () {
    return calcCampaignEndAt(campaign, initialTransactionAt);
  }, [campaign, isTrial, trialEndAt, initialTransactionAt]);
  return [start, end];
}