import _taggedTemplateLiteral from "/build/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject;
import { PACKAGE_TYPE, CONTRACTABLE_BUFFER } from "@/lib/constants";
import { gql } from "@apollo/client";
import { values, taxCalculation, isDateRangeIn } from "./helper";
export var typeDef = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  type Right {\n    id: ID!\n    name: String!\n    exercise_term: Int\n    exercise_term_unit: Int\n    view_term: Int\n    view_term_unit: Int\n    view_limit_date: String\n    validity_start_at: String\n    validity_end_at: String\n    price: Int\n    discount_price: Int\n    isSvod: Boolean\n    push_rank: Int\n    package_type: Int\n    discount_description: String\n    contractAllowed: Boolean\n    rights_control_type: Int\n  }\n"])));
export var resolvers = {
  Right: {
    id: function id(right) {
      return parseInt(right.id || right.right_id, 10);
    },
    price: function price(obj) {
      return taxCalculation(values(obj, "price"));
    },
    discount_price: function discount_price(obj) {
      return taxCalculation(values(obj, "discount_price"));
    },
    isSvod: function isSvod(obj) {
      return PACKAGE_TYPE["svod"] == values(obj, "package_type");
    },
    package_type: function package_type(obj) {
      return values(obj, "package_type");
    },
    push_rank: function push_rank(obj) {
      return values(obj, "push_rank");
    },
    discount_description: function discount_description(obj) {
      return values(obj, "discount_description");
    },
    contractAllowed: function contractAllowed(right) {
      if (right) {
        var _right$values, _right$values2;
        return isDateRangeIn(right === null || right === void 0 || (_right$values = right.values) === null || _right$values === void 0 ? void 0 : _right$values.delivery_start_date, right === null || right === void 0 || (_right$values2 = right.values) === null || _right$values2 === void 0 ? void 0 : _right$values2.delivery_end_date, {
          buffer: CONTRACTABLE_BUFFER,
          default: true
        });
      }
      return false;
    },
    rights_control_type: function rights_control_type(obj) {
      return values(obj, "rights_control_type");
    }
  }
};