import { useEffect } from "react";
import { useNotification, useOnLineStatus } from "@/hooks";
export default function NetworkNotification() {
  var onLine = useOnLineStatus();
  var _useNotification = useNotification(),
    showNotification = _useNotification.showNotification,
    hideNotification = _useNotification.hideNotification;
  useEffect(function () {
    if (!onLine) {
      showNotification("インターネットに接続されていません", {
        caution: true,
        fixed: true
      });
    } else {
      hideNotification({
        caution: true
      });
    }
  }, [onLine]);
  return null;
}