import { useAuthUserQuery, useMounted } from "@/hooks";
import routes from "@/lib/routes";
import { useRouter } from "next/router";
import { useEffect } from "react";
export function useRequireLogout() {
  var router = useRouter();
  var mounted = useMounted();
  var _useAuthUserQuery = useAuthUserQuery({
      fetchPolicy: "cache-first"
    }),
    data = _useAuthUserQuery.data,
    loading = _useAuthUserQuery.loading;
  useEffect(function () {
    if (mounted && !loading && data !== null && data !== void 0 && data.user) {
      router.push(routes.home);
    }
  }, [mounted, loading, data === null || data === void 0 ? void 0 : data.user]);
}