import dayjs from "@/lib/day";

// 生年月日のバリデート判定
// 判定条件（標準のDateオブジェクトだと100年以下を扱えないので、自前で実装する）
// 西暦100年以上→OK
// 西暦99年以下→NG
export function birthdayValidate(birthday) {
  var birthdayStr = birthday.split("-");
  var yearStr = !!birthdayStr && (birthdayStr === null || birthdayStr === void 0 ? void 0 : birthdayStr[0]);
  var yearInt = parseInt(yearStr, 10);
  if (!!birthday && dayjs(birthday).isValid() && birthday.includes("-") && yearInt > 99) {
    return true;
  } else {
    return false;
  }
}