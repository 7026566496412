import config from "@/lib/config";
// 定数を記述する
// => {"unknown"=>0, "man"=>1, "woman"=>2}
export var PALETTE_SCHEMAS = {
  1: "default",
  2: "ranking",
  3: "genre",
  4: "mylist",
  5: "entitled",
  6: "entitled_html_snippet"
};
export var PROFILE_MAX = 6;
export var GENDER = {
  unknown: 0,
  man: 1,
  woman: 2
};
export var GENDER_LABEL = {
  0: "回答しない",
  1: "男性",
  2: "女性"
};

// => {"view_term_unit_days"=>0, "view_term_unit_hours"=>1, "view_term_unit_minutes"=>2, "view_term_unit_seconds"=>3}
export var VIEW_TERM_UNIT = {
  0: {
    value: "day",
    label: "日"
  },
  1: {
    value: "hours",
    label: "時間"
  },
  2: {
    value: "minutes",
    label: "分"
  },
  3: {
    value: "secounds",
    label: "秒"
  }
};

// {"exercise_duration_unit_days"=>0, "exercise_duration_unit_hours"=>1, "exercise_duration_unit_minutes"=>2, "exercise_duration_unit_seconds"=>3}
export var EXERCISE_DURATION_UNIT = {
  0: {
    value: "day",
    label: "日間"
  },
  1: {
    value: "hours",
    label: "時間"
  },
  2: {
    value: "minutes",
    label: "分"
  },
  3: {
    value: "secounds",
    label: "秒"
  }
};
export var COURSE_TRIAL_DURATION_UNIT = {
  trial_term_unit_hour: {
    label: "時間",
    unit: "hour"
  },
  trial_term_unit_day: {
    label: "日間",
    unit: "day"
  },
  trial_term_unit_week: {
    label: "週間",
    unit: "week"
  },
  trial_term_unit_month: {
    label: "ヶ月",
    unit: "month"
  },
  trial_term_unit_year: {
    label: "年間",
    unit: "year"
  }
};
export var PACKAGE_TYPE = {
  tvod: [1, 2, 3],
  svod: 5
};
export var SLIDER_MAX_NUM = 17;
export var GRID_PAGE_LIMIT = 24;
export var LIST_PAGE_LIMIT = 10;
export var CANVAS_PAGE_LIMIT = 10;
export var ORDER_PAGE_LIMIT = 15;
export var GRAPHQL_MAX_LIMIT = 100;
export var RATING_AGE = {
  G: 0,
  PG12: 12,
  "R15+": 15,
  "R18+": 18,
  "R20+": 20
};
export var userRating = {
  0: {
    enableAge: 20,
    label: "なし",
    permitRating: ["R20+", "R18+", "R15+", "PG12", "G"]
  },
  1: {
    enableAge: 18,
    label: "R-20以上のコンテンツを表示しない",
    permitRating: ["R18+", "R15+", "PG12", "G"]
  },
  2: {
    enableAge: 15,
    label: "R-18以上のコンテンツを表示しない",
    permitRating: ["R15+", "PG12", "G"]
  },
  3: {
    enableAge: 12,
    label: "R-15以上のコンテンツを表示しない",
    permitRating: ["PG12", "G"]
  },
  4: {
    enableAge: 0,
    label: "PG-12以上のコンテンツを表示しない",
    permitRating: ["G"]
  }
};
export var rightsControlType = {
  unknown_rights: 0,
  free: 10,
  member_only: 20,
  entitled_only: 30
};
export var metaSchemas = {
  tvshow: {
    id: 1,
    key: "tv_show"
  },
  // 作品
  season: {
    id: 2,
    key: "tv_season"
  },
  // シーズン
  episode: {
    id: 3,
    key: "tv_episode"
  },
  // エピソード
  channel: {
    id: 4,
    key: "ott_channel"
  },
  // チャンネル
  linear: {
    id: 5,
    key: "linear"
  } // リニア 今回はないはず
};

export var defaultMetatags = {
  vod: {
    title: "SPOOX(スプークス) | スポーツや映画やアニメ、音楽ライブを動画で配信中！",
    keywords: "SPOOX,スプークス,動画配信,見放題,レンタル,スカパー！",
    description: "SPOOX(スプークス)では、スポーツや映画やアニメ、音楽ライブなど、様々な動画を配信中！月額見放題でも、1作品から購入でも楽しめます。",
    og: {
      title: "SPOOX(スプークス) | スポーツや映画やアニメ、音楽ライブを動画で配信中！",
      type: "website",
      url: config.host.vod,
      image: "".concat(config.host.vod, "/icon/ogp-spoox.png"),
      site_name: "SPOOX",
      description: "SPOOX(スプークス)では、スポーツや映画やアニメ、音楽ライブなど、様々な動画を配信中！月額見放題でも、1作品から購入でも楽しめます。"
    },
    twitter: {
      card: "summary",
      title: "SPOOX(スプークス) | スポーツや映画やアニメ、音楽ライブを動画で配信中！",
      image: "".concat(config.host.vod, "/icon/ogp-spoox.png"),
      description: "SPOOX(スプークス)では、スポーツや映画やアニメ、音楽ライブなど、様々な動画を配信中！月額見放題でも、1作品から購入でも楽しめます。"
    },
    appName: "SPOOX"
  },
  adult: {
    title: "SPOOX EX(スプークス イーエックス) | 人気女優や多彩なジャンルなど、様々なアダルト動画を配信中！",
    keywords: "SPOOX EX,スプークスイーエックス,動画配信,見放題,レンタル",
    description: "SPOOX EX(スプークスイーエックス)では、人気女優や多彩なジャンルなど、様々なアダルト動画を配信中！",
    og: {
      title: "SPOOX EX(スプークス イーエックス) | 人気女優や多彩なジャンルなど、様々なアダルト動画を配信中！",
      type: "website",
      url: config.host.adult,
      image: "".concat(config.host.adult, "/exicon/ogp-spoox-ex.png"),
      site_name: "SPOOX EX",
      description: "SPOOX EX(スプークスイーエックス)では、人気女優や多彩なジャンルなど、様々なアダルト動画を配信中！"
    },
    twitter: {
      card: "summary",
      title: "SPOOX EX(スプークス イーエックス) | 人気女優や多彩なジャンルなど、様々なアダルト動画を配信中！",
      image: "".concat(config.host.adult, "/exicon/ogp-spoox-ex.png"),
      description: "SPOOX EX(スプークスイーエックス)では、人気女優や多彩なジャンルなど、様々なアダルト動画を配信中！"
    },
    appName: "SPOOX EX"
  }
};
export var helpLink = function helpLink() {
  return config.staticLinks.help;
};
export var privacyLink = function privacyLink(adultMode) {
  if (adultMode) {
    return config.staticLinks.privacy.adult;
  } else {
    return config.staticLinks.privacy.vod;
  }
};
export var companyLink = function companyLink(adultMode) {
  if (adultMode) {
    return config.staticLinks.company.adult;
  } else {
    return config.staticLinks.company.vod;
  }
};
export var maintenanceLink = function maintenanceLink() {
  return config.staticLinks.maintenance;
};
export var appUserAgents = ["jp.logiclogic.skyperfectv.iptv", "jp.co.skyperfectv.iptv", "jp.co.skyperfectv.androidtvspod"];
export var deviceCodes = {
  pc: 1,
  iPhone: 2,
  android: 3,
  iPad: 4,
  androidTablet: 5
};
export var deviceCodesToString = {
  1: "pc",
  2: "iPhone",
  3: "android",
  4: "iPad",
  5: "androidTablet"
};
export var profileIconImages = {
  1: "".concat(config.staticFileHost, "/profile_icons/profile_1.svg"),
  2: "".concat(config.staticFileHost, "/profile_icons/profile_2.svg"),
  3: "".concat(config.staticFileHost, "/profile_icons/profile_3.svg"),
  4: "".concat(config.staticFileHost, "/profile_icons/profile_4.svg"),
  5: "".concat(config.staticFileHost, "/profile_icons/profile_5.svg"),
  6: "".concat(config.staticFileHost, "/profile_icons/profile_6.svg"),
  7: "".concat(config.staticFileHost, "/profile_icons/profile_7.svg"),
  8: "".concat(config.staticFileHost, "/profile_icons/profile_8.svg")
};
export var errorMessages = {
  notauthenticated: "ご利用可能なログイン情報が確認できません。再度ログインください。",
  unauthorized: "認証でエラーが発生しました。メールアドレス、パスワードをご確認ください。",
  invalidPasscode: "暗証番号が違います",
  pincode: "認証コードの有効期限が切れているか、コードが正しくありません。",
  signup: "エラーが発生しました。登録内容をご確認の上再度お試しください。",
  update: "ユーザー情報更新でエラーが発生しました。内容をご確認ください",
  invalidPassword: "パスワードが違います。入力したパスワードをご確認ください。",
  badPassword: "より強力なパスワードを入力してください。\n・文字数は8文字以上\n・「アルファベット」、「数字」、「記号(!@$#%+-_)」の3種類の組み合わせ",
  updatePassword: "パスワード更新でエラーが発生しました。内容をご確認ください",
  updateOwnerProfile: "オーナープロフィール更新でエラーが発生しました。内容をご確認ください",
  profile: "プロフィール追加でエラーが発生しました。内容をご確認ください",
  profileUpdate: "プロフィール更新でエラーが発生しました。内容をご確認ください",
  profileDelete: "プロフィール削除でエラーが発生しました。内容をご確認ください",
  userDelete: "ユーザー削除でエラーが発生しました。内容をご確認ください",
  paymentInstrumentsDeactivateUsed: "有効な契約で使用中のため削除できません",
  paymentInstrumentsDeactivateDefault: "デフォルトの支払い設定は無効化できません。",
  deleteViewingHistory: "視聴履歴の削除でエラーが発生しました。内容をご確認ください",
  inquiryError: "お問い合わせメール送信でエラーが発生しました。",
  unsubscribeValidationError: "バリデーションに失敗しました: 有効化日時は[2021-06-07 16:52]より過去の日時を指定してください, 失効日時は[2021-06-07 16:52]より未来の日時を指定してください",
  passwordReset: "パスワードリセットでエラーが発生しました",
  passcodeReset: "パスコードリセットでエラーが発生しました",
  subscriptionEstimate: "月額商品購入の確認でエラーが発生しました。",
  subscriptionChangePayment: "月額商品購入の支払い方法変更でエラーが発生しました、内容をご確認ください。",
  purchaseEstimate: "商品購入の確認でエラーが発生しました。",
  purchaseEstimateInapplicable: "入力いただいたクーポンの割引より、すでにお客様に適用されている割引の方が割引額が大きいため、適用することができません。",
  purchaseExecute: "商品購入でエラーが発生しました、内容をご確認ください。",
  purchaseDefaultPaymentError: "エラーが発生した為、購入ができませんでした。お手数ですが、再度「購入する」ボタンよりお手続きをお願いします。",
  subscribeExecute: "月額商品購入でエラーが発生しました、内容をご確認ください。",
  paymentRegister: "決済情報が登録できませんでした。お手数ですが、再度ご入力をお願いします。",
  unsubscribeExecute: "解約でエラーが発生しました、内容をご確認ください。",
  recaptcha: "クレジットカードをご登録いただくには認証が必要です。以下のチェックボックスにチェックを入れてご登録をお願いします。",
  advertisingPreferences: "ご案内メールの受信設定変更に失敗しました。しばらく時間をおいてから再度お試しください。"
};
export var inquiryList = {
  1: "視聴トラブル（エラーコード/エラーメッセージを詳細に記載ください）",
  2: "会員登録方法",
  3: "ログイン",
  4: "支払登録方法",
  5: "商品の購入/割引適用について",
  6: "商品の解約",
  7: "視聴方法",
  8: "番組の検索方法",
  9: "配信の有無",
  10: "番組内容",
  11: "退会方法",
  12: "請求金額確認",
  13: "ご要望/配信リクエスト",
  14: "メール配信の停止について",
  15: "その他"
};
export var contractedStatus = ["trial", "contracted"];
export var withdrawedStatus = ["applied_cancel", "canceled", "stopped", "trial_canceled"];
export var notBillingStatus = ["trial_canceled", "canceled", "stopped"];
export var PAYMENT_METHOD_NAMES = {
  1: "クレジットカード払い",
  2: "d払い",
  3: "ソフトバンクまとめて支払い・ワイモバイルまとめて支払い",
  4: "au かんたん決済／au WALLET",
  5: "PAYPAY",
  6: "マイスカ決済"
};
export var CONTRACTABLE_BUFFER = [60, "second"];
export var PURCHASABLE_BUFFER = [60, "second"];
export var PAYMENT_METHOD_ID = {
  CREDIT_CARD: 1,
  DOCOMO: 2,
  SOFTBANK: 3,
  AU: 4,
  PAYPAY: 5
};
export var ADULT_RESTRICTION_AGE = 18;
export var maskTargetKeys = ["password", "mail_address", "first_name", "last_name", "display_name", "birthday", "sex", "token", "token_key", "passcode", "alice_bs_numbers" // valuesに入っている
];

export var plalaQualityLevels = [{
  label: "低画質",
  selected: false,
  maximumHeight: 180
}, {
  label: "標準画質",
  selected: false,
  maximumHeight: 480,
  minimumHeight: 180
}, {
  label: "高画質",
  selected: false,
  maximumHeight: 540,
  minimumHeight: 480
}, {
  label: "高画質[HD]",
  selected: false,
  maximumHeight: 720,
  minimumHeight: 540
}, {
  label: "最高画質",
  selected: false,
  maximumHeight: 1080,
  minimumHeight: 720
}];
export var streaksQualityLevels = [{
  label: "低画質",
  selected: false,
  maximumHeight: 180
}, {
  label: "節約画質",
  selected: false,
  maximumHeight: 360,
  minimumHeight: 180
}, {
  label: "標準画質",
  selected: false,
  maximumHeight: 480,
  minimumHeight: 360
}, {
  label: "高画質",
  selected: false,
  maximumHeight: 540,
  minimumHeight: 480
}, {
  label: "高画質[HD]",
  selected: false,
  maximumHeight: 1080,
  minimumHeight: 540,
  maximumBandwidth: 2940000
}, {
  label: "最高画質",
  selected: false,
  maximumHeight: 1080,
  minimumHeight: 540,
  maximumBandwidth: 4200000,
  minimumBandwidth: 2940000
}];
export var fullscreenExcludeVideoOverlayStates = ["needlogin", "needlogin_and_contract", "entitlement_confirm", "geolocation", "suggest_app", "live_before", "dvr_live", "appkick", "rating", "playback_error", "error"];